@import "../variables";

.contacts {
    position: relative;
    background: url("../img/contacts-bg.jpg") no-repeat center left;
    background-size: cover;

    @media screen and (max-width: 1199px) {
        min-height: unset !important;
        padding: 70px 30px 120px;
    }

    @media screen and (max-width: 767px) {
        padding: 40px 30px 230px;
    }

    &__wrapper {

        // display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
        // padding-right: 7vw;

        margin-top: 13vh;

        @media (min-aspect-ratio: ~"16/9") {
            margin-top: 12vh;
        }

        @media only screen and (max-width: 1199px) {
            display: flex;
            justify-content: space-between;
            margin-top: 60px;
            padding-bottom: 72px;
        }

        @media screen and (max-width: 767px) {
            margin-top: 36px;
        }

        @media screen and (max-width: 575px) {
            display: block;
        }
    }

    &__info {
        @media only screen and (max-width: 991px) {
            width: 50%;
        }

        @media screen and (max-width: 575px) {
            width: 100%;
        }

        &-title {
            margin-bottom: 3vh;



            @media screen and (max-width: 575px) {
                margin-bottom: 20px;
            }
        }

        &-item {
            position: relative;
            padding-left: 40px;
            margin-bottom: 1em;

            @media screen and (max-width: 575px) {
                margin-bottom: 20px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;

                width: 20px;
                height: 20px;
                background: url("../img/icons/phone-yellow.svg") no-repeat center center;
                transform: translateY(-50%);


            }

            a {
                color: @white;
            }

            &_mail {
                &:before {
                    height: 16px;
                    background-image: url("../img/icons/mail-yellow.svg");
                }
            }

            &_address {
                &:before {
                    display: none;
                    // background-image: url("../img/icons/mail-yellow.svg");
                }
            }
        }
    }

    &__form {
        position: absolute;
        bottom: 14vh;
        right: 9vw;

        // margin-top: 5vh;

        @media only screen and (max-width: 1199px) {
            position: static;
        }
    }
}

.contacts-page .contacts {

    .section-title>div span,
    .section-title>div b,
    .section-title>div div {
        transform: translateY(0px) skewY(0deg);
    }

    @media only screen and (min-height: 820px) {
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        // height: 100vh !important;        
    }
}