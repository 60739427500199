@import 'variables';


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.preloader.is-hidden .preloader__img img{
    opacity: 0;
    transition: 0.5s ease;
}
.preloader__img img {
    width: 200px;
    height: 70px;
    max-width: 80%;
}

.preloader__timeline{
    display: none !important;
}

@keyframes gradient {

    0% {
        visibility: hidden;
        background-position: 110% 0%;
    }

    50% {
        visibility: visible;
    }

    100% {
        visibility: hidden;
        background-position: 0% 110%;
    }
}

canvas {
    max-width: 100vw;
}

body {
    font-family: @font-montserrat;
    font-weight: normal;
    color: @dark-text;
    font-size: 18px;
    line-height: 22px;


    @media screen and (max-height: 850px) {
        font-size: 15px;
    }

    @media screen and (max-height: 690px) {
        font-size: 14px;
    }

    @media screen and (max-width: 1199px) {
        font-size: 16px;
    }
}

@media screen and (max-width: 1199px) {
    html {
        overflow-y: visible;
        max-width: 100%;
    }
}

.mainview {
    font-size: 18px;

    @media screen and (max-height: 850px) {
        font-size: 15px;
    }

    @media screen and (max-height: 690px) {
        font-size: 14px;
    }

    @media screen and (max-width: 1199px) {
        font-size: 16px !important;
    }

    @media screen and (max-width: 991px) {
        font-size: 15px !important;
    }
}

.mainbag {
    max-width: 83vw;

    @media screen and (max-width: 1199px) {
        max-width: 100vw;
    }
}

a {
    text-decoration: none;

    &:hover {
        color: @yellow;
    }
}

li {
    list-style: none;
}

.heading {

    font-weight: bold;
    font-size: 4.45em;
    line-height: 1;
    color: #030B27;

    &_white {
        color: @white;
    }

    @media screen and (max-height: 850px) {
        font-size: 4em;
    }

    @media screen and (max-height: 690px) {
        font-size: 3.5em;
    }

    @media screen and (max-width: 991px) {
        font-size: 48px;
        line-height: 1;
    }

    @media screen and (max-width: 767px) {
        font-size: 36px;
        line-height: 44px;
    }

    @media screen and (max-width: 575px) {
        font-size: 28px;
        line-height: 34px;
    }
}

.heading-alt {
    font-family: @font-montserrat-alt;
    // font-weight: 500;
    // font-size: 72px;
    // line-height: 88px;
    font-size: 4em;
    line-height: 1;
    color: #030B27;

    &_white {
        color: @white;
    }

    @media screen and (max-height: 850px) {
        font-size: 3.5em;
    }

    @media screen and (max-height: 690px) {
        font-size: 3em;
    }

    @media screen and (max-width: 991px) {
        font-size: 50px;
        line-height: 54px;
    }

    @media screen and (max-width: 767px) {
        font-size: 38px;
        line-height: 42px;
    }
}


.section-title {
    &>div {
        overflow: hidden;
        padding-bottom: 5px;

        span,
        b,
        div {
            display: block;
            transform: translateY(300%) skewY(10deg);
        }
    }

    span {
        color: @nocolor;
        -webkit-text-stroke: 2px @dark-text;

        font-family: @font-montserrat-alt;
        font-size: 0.9em;
        font-weight: 600;
        line-height: 1;
        // color: #030B27;

        @media only screen and (max-width: 767px) {
            font-size: 36px;
            line-height: 44px;
            -webkit-text-stroke: 1px @dark-text;
        }
    }

    &_white span {
        -webkit-text-stroke: 2px @white;
    }

    b {
        font-family: @font-montserrat;
        font-weight: bold;
        font-size: 1.1em;
        line-height: 1;
        color: @nocolor;
        -webkit-text-stroke: 2px #C6C8CE;

        @media only screen and (max-width: 767px) {
            font-size: 36px;
            line-height: 44px;
            -webkit-text-stroke: 1px #C6C8CE;
        }
    }

    &_blue b {
        -webkit-text-stroke: 2px #07174A;

        @media only screen and (max-width: 767px) {
            -webkit-text-stroke: 1px #07174A;
        }
    }
}

.is-visible {
    .section-title>div {

        span,
        b,
        div {
            transform: translateY(0px) skewY(0deg);
            transition: transform 1s ease-in-out;
        }
    }
}

.title {
    font-weight: 800;
    // font-size: 18px;
    // line-height: 22px;

    font-size: 1em;
    line-height: 1.2;
    color: #030B27;

    &_white {
        color: @white;
    }

    @media screen and (max-width: 991px) {
        font-size: 18px;
        line-height: 1;
    }

}

.title-alt {
    font-family: @font-montserrat-alt;
    font-style: normal;
    font-weight: bold;
    // font-size: 24px;
    // line-height: 29px;

    font-size: 1.33em;
    line-height: 1.2;
    color: #030B27;

    @media screen and (max-width: 1366px) {
        font-size: 1.25em;
    }

    @media screen and (max-height: 850px) {
        font-size: 1.2em;
    }

    @media screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 1;
    }

    @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
    }

    @media screen and (max-width: 575px) {
        // font-weight: normal;
        font-size: 16px;
        line-height: 19px;
    }

    &_white {
        color: @white;
    }

    strong {
        // font-size: 36px;
        // line-height: 44px;

        font-size: 1.5em;
        line-height: 122%;

        @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 29px;
        }

    }

    b {
        color: @yellow;
    }
}

.title-light {
    font-weight: 300;
    // font-size: 26px;
    // line-height: 32px;

    font-size: 1.4em;
    font-size: 1.7em;
    color: #030B27;

    @media screen and (max-height: 850px) {
        font-size: 1.2em;
    }

    @media screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 1;
    }

}

.title-big {
    font-family: @font-montserrat-alt;
    font-style: normal;
    font-weight: bold;
    // font-size: 36px;
    // line-height: 44px;
    color: #07174A;

    font-size: 2em;
    line-height: 1.2;

    @media screen and (max-height: 690px) {
        font-size: 1.5em;
    }

    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 29px;
    }

    @media screen and (max-width: 575px) {
        font-size: 18px;
        line-height: 1;
    }

    b {
        color: @yellow;
        font-weight: 600;
        font-size: 1em;
        line-height: 1.2;

        @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 29px;
        }

        @media screen and (max-width: 575px) {
            font-size: 18px;
            line-height: 1;
        }
    }
}

.text {
    font-weight: normal;
    // font-size: 18px;
    // line-height: 22px;
    font-size: 1em;
    line-height: 1.2;
    color: #030B27;

    &>* {
        margin-bottom: 0.7em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 19px;
    }

    &_yellow {
        color: @yellow;
    }

    li {
        // padding-left: 10px;
        margin-bottom: 0.3em;
        line-height: 1.2;
    }
}

.btn {
    font-weight: 600;
    // font-size: 14px;
    // line-height: 17px;
    letter-spacing: 0.05em;
    color: @black;

    font-size: 0.77em;
    line-height: 1;

    @media (min-aspect-ratio: ~"16/9") {
        font-size: 14px;
        line-height: 17px;
    }

    @media screen and (max-width: 991px) {
        font-size: 14px;
    }
}

.button {

    display: block;
    width: 207px;
    height: 40px;
    background: @grey-light;
    border: none;

    font-family: @font-montserrat-alt;
    font-weight: normal;
    // font-size: 14px;
    font-size: 0.77em;
    line-height: 40px;
    color: #07174A;
    text-align: center;
    text-decoration: none;

    transition: 0.33s ease;



    &:hover {
        background: #07174A;
        color: @grey-light;
    }

    @media screen and (max-width: 575px) {
        font-size: 14px;
        color: #9EA1A9;
    }
}

.text-small {
    font-weight: normal;
    // font-size: 14px;
    // line-height: 17px;

    font-size: 0.77em;
    line-height: 1em;

    b {
        font-weight: normal;
        color: @yellow;
    }
}

.link-arrow {

    .btn;

    color: #07174A;

    position: relative;
    border-bottom: 2px solid @blue;

    &:after,
    &::before {
        content: '';
        position: absolute;
        right: -19px;
        top: 50%;
        transform: translate(100%, -50%);
        display: block;
        width: 20px;
        height: 16px;
        background: url("../img/icons/link-arrow.svg") no-repeat center center;
        background-size: contain;

        transition: 0.33s ease;
    }

    &::before {
        background-image: url("../img/icons/link-arrow-yellow.svg");

        opacity: 1;
        visibility: hidden;
    }

    &:hover {

        color: inherit;

        &::after {
            opacity: 0;
            visibility: hidden;
            transition: 0.33s ease;
        }

        &::before {
            opacity: 1;
            visibility: visible;
            transition: 0.33s ease;
        }
    }
}

.page-wrap {
    position: relative;
    padding-left: 17vw;
    overflow: hidden;
    max-width: 100vw;


    @media screen and (max-width: 1199px) {
        padding-left: 0;
    }

    &.scrolled {
        // overflow: scroll;
        overflow-y: scroll;

        .mainbag {
            overflow-y: scroll;
            height: auto;
        }

        .p-100 {
            height: auto;
            min-height: 100vh;

            @media screen and (max-width: 767px) {
                min-height: auto;
                width: 100%;
                overflow: hidden;
            }
        }

        // .footer {
        //     position: absolute;
        //     z-index: 99;
        // }
    }
}

body.scrolled {
    overflow: scroll;

}

.grey-bg {
    background: #F3F3F3;
}

.darkgrey-bg {
    background: #C6C8CE;
}

.dark-bg {
    background: #030B27;
}

.yellow-bg {
    background: @yellow;
}

/* section padding  */

.p-100 {
    height: 100vh;
    padding: 11vh 2vw 1vh;



    @media (min-aspect-ratio: ~'16/9') and (min-height: 850px) {
        padding: 7vh 2vw 1vh;
    }

    @media (min-aspect-ratio: ~'16/9') and (max-height: 849px) {
        padding: 5vh 2vw 1vh;
    }

    @media screen and (max-width: 991px) and (max-height: 600px) {
        height: auto;
    }

    @media screen and (max-width: 991px) {
        padding: 48px 30px;
    }

    @media screen and (max-width: 575px) {
        padding: 40px 15px;
    }


}

/* header */

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 17vw;
    min-width: 215px;
    height: 100vh;

    display: flex;
    flex-direction: column;

    background: @white;
    padding: 3vh 1.2vw 10vh;

    transition: 0.5s linear;
    z-index: 99;

    @media screen and (max-width: 1440px) {
        padding: 3vh 10px 10vh;
    }

    @media screen and (max-width: 1199px) {
        z-index: 9999999;

        width: 100%;
        height: auto;
        justify-content: center;



        padding: 8px 30px;

    }

    @media screen and (max-width: 767px) {
        height: 56px;
        padding: 8px 16px;
    }

    &__logo {

        @media screen and (max-width: 1199px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 120px;
            margin: 0 auto;
        }

        &-link {
            transition: 0.5s linear;
            font-size: 18px;

            span {
                // transition: 0.5s linear;
            }

            &:hover {
                color: inherit;

                span {
                    color: @yellow;
                }
            }

            @media screen and (max-width: 1199px) {
                text-align: center;
                font-size: 16px;
            }

            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }

        &-img {
            position: relative;

            width: 100%;
            height: 2.7vh;
            min-height: 24px;
            margin-bottom: 6px;

            @media screen and (max-width: 1199px) {
                width: 120px;
                height: auto;
                min-height: 20px;
                margin-bottom: 6px;
            }

            .white {
                opacity: 0;
                visibility: hidden;

                transition: 0.5s linear;
            }

            svg,
            img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                height: 100%;

                opacity: 1;
                visibility: visible;

                transition: 0.5s linear;

                path {
                    transition: 0.5s linear;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;
        margin-top: 4vh;

        @media screen and (max-width: 1199px) {
            position: fixed;
            top: 56px;
            left: 0;
            right: 0;
            z-index: -1;

            transform: translateY(-150%);

            overflow: scroll;

            background: @white;
            width: 100%;
            height: calc(100% - 56px);

            margin: 0;
            padding: 36px 30px;

            transition: 0.6s ease;

            &.is-active {
                transform: translateY(0);
            }


        }

        @media screen and (max-width: 767px) {
            padding: 24px 15px;
        }
    }

    &__nav {

        &-list {
            margin-bottom: 3vh;

            @media screen and (max-width: 1199px) {
                margin-bottom: 36px;
            }
        }

        &-sublist {
            margin: 1.6vh 0;
            padding-left: 16px;

            @media screen and (max-width: 1199px) {
                margin: 12px 0;
            }

            .header__nav-item a {
                position: relative;
                // padding-left: 16px;


            }

            .header__nav-item.is-active a:before {
                background: @yellow;
            }
        }

        &-item {
            position: relative;

            margin-bottom: 1vh;

            @media screen and (max-width: 1366px) {
                margin-bottom: 0;
            }

            @media screen and (max-width: 1199px) {
                margin-bottom: 1px;
            }

            a {
                display: inline-block;
                position: relative;
                color: #030B27;
                padding-bottom: 2px;

                overflow: hidden;

                transition: 0.25s linear;

                span {
                    display: inline-block;
                    position: relative;

                    white-space: nowrap;

                    @media screen and (max-width: 1440px) {
                        font-size: 17px;
                    }

                    @media screen and (max-width: 1366px) {
                        font-size: 15px;
                    }

                    @media screen and (max-width: 1199px) {
                        position: unset;
                        white-space: unset;

                        span {
                            &:nth-child(2) {
                                // display: none;
                            }
                        }
                    }

                    &::before {
                        content: '';

                        position: absolute;
                        bottom: 0;
                        left: -2px;

                        display: block;
                        width: 100%;
                        height: 2px;
                        background: @yellow;

                        transform: translateX(-100%);
                        opacity: 1;

                        transition: 0.4s ease;
                    }
                }

                &:hover span::before {
                    transform: translateX(0%);
                    opacity: 1;
                }
            }

            &.is-active {
                a {
                    color: @yellow;
                    font-weight: 800;

                    &:before {
                        transform: translateX(0%);
                        opacity: 1;
                    }

                    .header__nav-sublist .header__nav-item a {
                        color: @yellow;

                        &::before {
                            background: @yellow;
                        }
                    }
                }
            }

            &_parent {

                &>a {
                    padding-right: 15px;

                    &:before {
                        top: 50%;
                        left: unset;
                        right: 2px;

                        width: 5px;
                        height: 5px;
                        border: 1.5px solid @dark-text;
                        border-top: 0;
                        border-left: 0;
                        background: transparent;

                        transform: translateY(-50%) rotate(45deg);

                        opacity: 1;
                    }

                    &:hover {
                        &:before {
                            transform: translateY(-50%) rotate(45deg);
                        }
                    }
                }
            }
        }
    }

    &__lang {
        margin-bottom: 5vh;
        margin-top: 2vh;

        @media screen and (max-width: 1199px) {
            margin-bottom: 48px;
            margin-top: 24px;
        }
    }

    &__contacts {
        &-title {
            position: relative;
            padding-left: 30px;
            margin-bottom: 1.6vh;
            transition: 0.24s linear;

            @media screen and (max-width: 1199px) {
                margin-bottom: 24px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;

                display: block;
                width: 20px;
                height: 20px;
                background: url("../img/icons/phone.svg") no-repeat center center;
                background-size: contain;
                transition: 0.33s ease;
            }
        }

        a {
            display: block;
            margin-bottom: 5px;
            color: #07174A;
            transition: 0.25s linear;

            &:hover {
                color: @yellow;
            }
        }
    }

    &__burger {
        display: none;

        @media screen and (max-width: 1199px) {
            position: absolute;
            top: 50%;
            left: 30px;

            transform: translateY(-50%);

            display: block;

            width: 36px;
            height: 15px;
            min-height: 15px;
        }

        @media screen and (max-width: 767px) {
            left: 13px;
        }

        span {
            position: absolute;
            top: 0;

            display: block;
            width: 36px;
            height: 3px;
            background: #07174A;
            border-radius: 10px;

            opacity: 1;

            transition: transform 0.25s ease, top 0.25s ease 0.35s, max-width 0.25s ease 0.6s;

            &:nth-child(2) {
                max-width: 60%;
                top: 50%;

                transform: translateY(-25%);
                transition: opacity 0.1s ease 0.15s, top 0.25s ease 0.35s, max-width 0.25s ease 0.7s;
            }

            &:nth-child(3) {
                max-width: 30%;
                top: 100%;

                transition: transform 0.25s ease, top 0.25s ease 0.35s, max-width 0.25s ease 0.6s;
            }
        }

        &.is-active {
            span {
                max-width: 100%;
                top: 50%;

                &:nth-child(1),
                &:nth-child(3) {
                    transform: rotate(45deg);
                }

                transition: max-width 0.25s ease,
                top 0.25s ease 0.4s,
                transform 0.25s ease 0.6s;

                &:nth-child(2) {
                    opacity: 0;

                    transition: max-width 0.25s ease, opacity 0.1s ease 0.5s;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    transition: max-width 0.25s ease 0.125s, top 0.25s ease 0.4s, transform 0.25s ease 0.6s;
                }
            }
        }
    }

    .lang-switcher__list-item a {
        color: @dark-text;

        &:hover {
            color: @yellow;
        }
    }

    &.is-contrasted {
        background: #030B27;

        .header__logo {
            .white {
                opacity: 1;
                visibility: visible;
            }

            .blue {
                opacity: 0;
                visibility: hidden;
            }
        }

        .header__logo-link {
            color: @white;

        }

        .header__logo path {
            fill: #FFFFFF;
        }

        .header__nav-item {
            a {
                color: @white;

                @media screen and (max-width: 1199px) {
                    color: @dark-blue;
                }
            }

            &.is-active a {
                color: @yellow;
            }
        }

        .header__nav-search {
            border-bottom-color: @white;

            &::before {
                background: url("../img/icons/search-white.svg");
            }
        }

        .header__lang {
            .lang-switcher__icon {
                path {
                    fill: #FFFFFF;
                }
            }

            .lang-switcher__langs {
                &:after {
                    border-color: @white transparent transparent transparent;
                }
            }

            .lang-switcher__list-item {
                &:after {
                    background: @white;
                }

                a,
                span {
                    color: @white;

                    &:hover {
                        color: @yellow;
                    }
                }
            }

            color: @white;


        }

        .header__contacts-title {
            color: @white;

            &::before {
                background: url("../img/icons/phone-yellow.svg") no-repeat center center;
            }
        }

        .header__contacts a {
            color: @white;
        }

        .header__nav-item_parent>a:before {
            border: 1.5px solid @white;
            border-top: 0;
            border-left: 0;
        }

        .header__burger span {
            background: @white;
        }
    }
}

/* search */

.search {
    position: relative;
    border-bottom: 1px solid @black;

    height: 38px;

    &__input {
        width: 100%;
        height: 100%;
        padding: 9px 0 9px 30px;
        background: @nocolor;
        border: none;

        outline: none;
        transition: 0.25s linear;
    }

    &:before {
        content: '';

        position: absolute;
        left: 0;
        bottom: 9px;

        display: block;
        width: 20px;
        height: 20px;
        background: url("../img/icons/search.svg");

        border: none;
        transition: 0.33s ease;
    }

}

/* lang-switcher */

.lang-switcher {
    display: flex;
    align-items: center;


    &__icon {
        display: flex;
        height: 15px;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    &__langs {
        position: relative;
        margin-left: 8px;
        padding-right: 6px;

        cursor: pointer;

        transition: 0.25s linear;

        // &:after {
        //     content: '';
        //     position: absolute;
        //     left: 100%;
        //     top: 30%;

        //     display: block;
        //     width: 0;
        //     height: 0;
        //     border-style: solid;
        //     border-width: 7.5px 5px 0 5px;
        //     border-color: @dark-text transparent transparent transparent;
        //     border-radius: 10%;

        //     transition: 0.25s linear;

        // }

        // &:hover {
        //     .lang-switcher__list {
        //         opacity: 1;
        //         visibility: visible;
        //     }
        // }

    }

    &__current {
        transition: 0.25s linear;
    }

    &__list {
        // position: absolute;
        // top: 100%;

        // opacity: 0;
        // visibility: hidden;

        // transition: 0.25s linear;

        display: flex;

        &-item {
            position: relative;
            margin-right: 15px;

            span,
            a {
                transition: 0.25s linear;
            }

            span {
                cursor: text;
            }

            &_current a,
            &_current span {
                font-weight: 600;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: -7.5px;

                transform: translateY(-50%);

                display: block;
                height: 80%;
                width: 2px;
                background: @dark-blue;

                transition: 0.25s linear;
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    display: none;
                }
            }
        }
    }


}

/* custom list (yellow rect) */

.custom-list {
    li {
        position: relative;
        padding-left: 35px;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            display: block;
            width: 20px;
            height: 3px;
            border-radius: 2px;
            background: @yellow;
        }
    }
}

/* footer */

.footer {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 1.5vh 0;
    text-align: center;

    background: @dark-text;

    @media screen and (max-width: 991px) {
        display: block;
    }

    @media screen and (max-width: 767px) {
        // position: static;
    }

    &__nav {
        position: relative;

        margin-right: 2vw;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -0.75vw;

            display: block;
            width: 1px;
            height: 100%;
            background-color: @white;

        }

        &-list {
            display: flex;

            @media screen and (max-width: 991px) {
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 15px;
            }

            @media screen and (max-width: 767px) {
                display: block;
            }
        }

        &-item {
            margin: 0 1vw;


            @media screen and (max-width: 991px) {
                width: 45%;
                margin: 5px 0;
            }

            @media screen and (max-width: 767px) {
                margin: 10px 0;
                width: 100%;
            }

            a {
                .btn;
                letter-spacing: 0;
                font-weight: normal;
                color: @white;

                white-space: nowrap;

                &:hover {
                    color: @yellow;
                }
            }
        }
    }

    &__info,
    &__info-dev {

        .btn;
        line-height: 1;
        letter-spacing: 0;
        font-weight: normal;
        color: @white;

        @media screen and (max-width: 575px) {
            display: block;
            margin: 5px auto 0;


            font-size: 14px;
            line-height: 17px;
        }

    }

    &__info-dev {
        margin-left: 25px;
    }

    &__info {
        @media screen and (max-width: 575px) {
            position: relative;
            padding-top: 8px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 15px;
                right: 15px;

                display: block;
                height: 1px;

                background: rgb(255, 255, 255);
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.95) 30%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.95) 70%, rgba(255, 255, 255, 0.9) 80%, rgba(255, 255, 255, 0.8) 90%, rgba(255, 255, 255, 0) 100%);


            }
        }
    }
}

/* hero */

.hero {
    position: relative;
    height: 100vh;
    overflow: hidden;

    &__item {
        display: flex !important;
        flex-direction: column;
        width: 100%;
        height: 100vh;

        @media screen and (max-width: 991px) {
            min-height: unset !important;
            height: 50vh;
        }

        @media screen and (max-width: 767px) {
            justify-content: space-between;
        }

        &-img {

            width: 100%;
            height: 78vh;
            object-fit: cover;

            @media screen and (min-aspect-ratio: ~'16/9') {
                height: 75vh;
            }

            @media screen and (max-width: 991px) {
                // height: calc(100vh - 200px);
                height: 70%;
            }

        }

        &-content {
            width: 100%;
            height: 23vh;
            padding: 9vh 8vw 6vh 25vw;
            background: #030B27;

            @media screen and (min-aspect-ratio: ~'16/9') {
                height: 25vh;
                padding: 7vh 20vw 0 25vw;
            }

            @media screen and (max-width: 1199px) {
                // padding: 90px 120px 50px 120px;
                padding: 24px 20% 48px 20%;

                display: flex;
                align-items: center;
                text-align: center;
            }

            @media screen and (max-width: 991px) {
                height: 200px;
                padding: 24px 120px 30px;
            }

            @media screen and (max-width: 767px) {
                padding: 12px 5% 30px;
                height: 200px;
            }

        }

        &-text {
            height: 8vh;
            max-height: min-content;

            margin: 0 0 0 auto;

            color: @white;

            @media screen and (max-width: 1199px) {
                margin: 0 auto;
            }

            @media screen and (max-width: 575px) {
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    @media screen and (max-width: 991px) {
        min-height: unset !important;
        height: 50vh;
        justify-content: flex-start;
        margin-top: 56px;
    }
}

/*slider nav*/
/*
.slider-nav {
    position: absolute;
    top: 80vh;
    left: 25vw;
    z-index: 999;
    display: flex;
    align-items: center;

    @media screen and (min-aspect-ratio: ~'16/9') {
        top: 77vh;
    }

    @media screen and (max-width: 1199px) {
        left: 120px;
    }

    @media screen and (max-width: 767px) {
        left: 50%;
        top: unset;
        bottom: 160px;
        transform: translateX(-50%);

        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 5%;
    }

    &__button {
        width: 20px;
        height: 16px;

        cursor: pointer;

        svg {
            width: 100%;
        }
    }
}

.dots {

    &__list {
        display: flex;
        align-items: center;
        margin: 0 20px;

        @media screen and (max-width: 767px) {
            margin: 0 10px;
        }

        li {
            display: flex;
            align-items: center;
        }
    }

    &__item,
    button {
        position: relative;
        width: 60px;
        height: 2px;
        margin: 1px 10px 0;
        background: @grey-light;
        border: none;
        border-radius: 2px;

        overflow: hidden;
        font-size: 0;

        @media screen and (max-width: 767px) {
            width: 30px;
            margin: 0 5px;
        }

        &::before {
            content: '';

            position: absolute;
            top: -1px;
            left: 0px;
            width: 100%;
            height: 4px;
            background: @yellow;
            border-radius: 4px;

            transform: translateX(-100%);

        }



    }

    .slick-active {

        .dots__item,
        button {
            &::before {
                animation: timer 5s linear;
                animation-fill-mode: backwards;
            }
        }
    }
}
*/

.slider-nav {
    position: absolute;
    top: 80vh;
    left: 25vw;
    z-index: 999;
    display: flex;
    align-items: center;

    @media screen and (min-aspect-ratio: ~'16/9') {
        top: 77vh;
    }

    @media screen and (max-width: 1199px) {
        // left: 120px;
        left: 50%;
        top: unset;
        bottom: 24px;
        transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 991px) {
        left: 50%;
        top: unset;
        bottom: 15px;
        transform: translateX(-50%);
    }

    @media screen and (max-width: 767px) {
        left: 50%;
        top: unset;
        bottom: 15px;
        transform: translateX(-50%);

        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 5%;
    }

    &__button {
        width: 20px;
        height: 16px;

        cursor: pointer;

        svg {
            width: 100%;
        }
    }
}

.dots {

    &__list {
        display: flex;
        align-items: center;
        margin: 0 20px;

        @media screen and (max-width: 767px) {
            margin: 0 10px;
        }

        li {
            display: flex;
            align-items: center;
        }
    }

    &__item,
    button {
        position: relative;
        width: 60px;
        height: 2px;
        margin: 1px 10px 0;
        background: @grey-light;
        border: none;
        border-radius: 2px;

        overflow: hidden;
        font-size: 0;

        @media screen and (max-width: 767px) {
            width: 30px;
            margin: 0 5px;
        }

        &::before {
            content: '';

            position: absolute;
            top: -1px;
            left: 0px;
            width: 100%;
            height: 4px;
            background: @yellow;
            border-radius: 4px;

            transform: translateX(-100%);

        }



    }

    .slick-active {

        .dots__item,
        button {
            &::before {
                animation: timer 5s linear;
                animation-fill-mode: backwards;
            }
        }
    }
}

.callback {
    border-radius: 4px;
    padding: 40px 74px;
    background: @white;

    @media (min-aspect-ratio: ~"16/9") {
        margin-top: 2vh;

        // padding: 4vh 74px;
    }

    @media only screen and (max-width: 767px) {
        padding: 35px 50px 25px;
    }

    @media screen and (max-width: 575px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 25px 15px 15px;
    }

    &__title {

        font-family: @font-montserrat-alt;

        @media screen and (max-width: 575px) {
            margin-bottom: 30px;

            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #030B27;

        }
    }

    &__form {
        margin-top: 2.5vh;

        @media (min-aspect-ratio: ~"16/9") {
            margin-top: 2vh;
        }

        @media screen and (max-width: 575px) {
            width: 100%;

            .field {
                width: 100%;
            }


        }
    }
}


.form {
    background: @white;
}

.field {
    width: 18vw;
    margin-bottom: 2vh;
    border-bottom: 1px solid #C6C8CE;

    @media screen and (max-width: 991px) {
        width: 220px;
    }

    &__label {
        display: block;
        font-weight: 400;
    }

    &__input {
        width: 100%;
        border: none;
        background: transparent;
        font-weight: 400;
        padding: 7px 0 10px;

        outline: none;

        &::placeholder {
            color: #C6C8CE;
        }
    }



    &_select {
        border: 1px solid #C6C8CE;

        &::placeholder {
            color: @dark-text;
        }
    }

    &_button {
        border-bottom: none;
        margin-top: 3vh;

        input {
            margin: 0 auto;
        }
    }

    &.error{
        border-bottom-color: red !important;
        .field__label{
            color: red !important;
        }
        input::placeholder{
            color: red !important;
        }
    }
}

.field-file {
    position: relative;
    text-align: center;
    overflow: hidden;

    label {
        font-weight: 800;
        cursor: pointer;
    }

    input {
        position: absolute;
        top: 100%;
        left: 100%;
        opacity: 0;
        height: 1px;
        visibility: hidden;
    }
}


.select {
    position: relative;

    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: rgba(56, 55, 57, 0.8);

    &__checkbox {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 40px;
        opacity: 0;


        &:checked {

            &~.select__list {
                max-height: 999px;
                transition: 1s ease;
            }

        }
    }

    &__default {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 182px;
        height: 40px;
        padding: 6px 36px 7px 7px;

        @media screen and (max-width: 767px) {
            height: 36px;
        }
    }


    &__value {
        display: inline-block;
        overflow: hidden;

        span {
            font-family: @font-montserrat-alt;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */
            color: #07174A;

            white-space: nowrap;

            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }
    }


    &__arrow {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);

        display: block;
        width: 13px;
        height: 14px;
        background: url("../img/icons/arrow-down-form.svg") no-repeat center center;
        background-size: contain;
    }

    &__list {
        position: relative;
        will-change: max-height;
        max-height: 0;
        overflow: hidden;
        width: 100%;

        transition: max-height 1s ease;

        &-item {

            transition: 0.25s ease;

            input {
                position: absolute;
                top: -99px;
                left: -99px;

                opacity: 0;
                visibility: hidden;

                &:checked~label {

                    background: @yellow;
                    color: @white;
                }
            }

            label {
                display: block;
                width: 100%;
                padding: 10px 5px;
                font-family: @font-montserrat-alt;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #07174A;

                @media screen and (max-width: 767px) {
                    padding: 5px;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            &:hover label {
                background: @grey-light;
                color: @dark-text;
            }
        }
    }

}

.legend {
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        font-size: 18px;
        line-height: 22px;
        color: #030B27;

        @media screen and (max-width: 575px) {
            font-size: 16px;
        }

        span {
            display: inline-block;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: #07174A;
            margin-right: 8px;

            @media screen and (max-width: 575px) {
                width: 20px;
                height: 20px;
            }
        }
    }
}

@keyframes timer {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: -17vw;
    right: 0;
    bottom: 0;
    z-index: 99999;
    width: 100vw;

    display: none;

    background: rgba(0, 0, 0, 0.6);

    @media screen and (max-width: 1199px) {
        position: fixed;
        left: 0;
        z-index: 9999;
    }

    @media screen and (max-width: 767px) {
        padding: 20px 30px;
    }

    &.is-active {
        display: block;
    }
}

.popup {
    position: absolute;

    top: 50%;
    left: 50%;
    z-index: 999999;

    display: none;
    // align-items: flex-start;
    align-items: center;
    justify-content: center;
    width: 486px;
    max-width: 90%;
    height: 363px;
    padding: 30px 90px;
    border-radius: 4px;

    transform: translate(-15vw, -50%);

    background: url('../img/popup-bg-path.svg') no-repeat top right, @white;
    background-size: contain;


    &_cv {
        background: url('../img/popup-bg-cv.svg') no-repeat 12px top, @white;
        background-size: contain;
    }

    @media screen and (max-width: 1199px) {
        position: fixed;
        z-index: 9999;

        transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 767px) {
        padding: 20px 70px;
    }


    @media screen and (max-width: 575px) {
        padding: 20px 30px;
    }

    &.is-active {
        display: flex;

    }

    &__text {
        font-family: @font-montserrat-alt;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: @dark-blue;

        b {
            color: @white;
            -webkit-text-stroke: 1px @dark-text;
        }

     

        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;

        width: 25px;
        height: 25px;

        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 0;
            bottom: 0;

            width: 2px;
            border-radius: 2px;
            background: @dark-blue;

            transform: translateX(-50%) rotate(45deg);
        }

        &:after {
            transform: translateX(-50%) rotate(-45deg);
        }

        @media screen and (max-width: 575px) {
            top: 10px;
            right: 10px;

            width: 20px;
            height: 20px;
        }
    }

    &-job {
        align-items: flex-start;
        padding: 20px 40px;
        width: 90%;
        left: 17vw;
        height: auto;
        max-height: 90%;
        overflow: hidden;

        padding: 30px 40px;

        @media screen and (max-width: 1199px) {
            position: fixed;
            left: 50%;
            top: 100px;

            align-items: flex-start;
            transform: translate(-50%, 0);
            max-width: 90%;
            max-height: 80%;
            overflow-y: scroll;
        }

        @media screen and (max-width: 575px) {
            padding: 30px 20px 20px;
        }

        .popup__text {
            font-family: @font-montserrat;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.15;
            text-align: left;
            height: 100%;

            transition: transform 0.33s ease-in-out;

            li {
                font-family: @font-montserrat;
                position: relative;
                padding-left: 10px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0.5em;
                    left: 2px;

                    display: block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: @black;
                }
            }

            b {
                font-family: @font-montserrat;
                font-weight: 600;
                -webkit-text-stroke: unset;
                color: @dark-text;

            }

            @media screen and (max-width: 1199px) {
                font-size: 14px;
                overflow: hidden;
            }
        }

        .popup__buttons {
            position: absolute;
            bottom: 20px;
            right: 20px;

            width: 30px;
            display: none;



            &.is-visible {
                display: block;
            }

            &>div {
                width: 30px;
                height: 30px;
                margin: 10px 0;
                background: url("../img/icons/arrow-down-form.svg") no-repeat center center;
                background-size: 100%;
                cursor: pointer;
            }

            &-top {
                display: none;
                transform: rotate(180deg);
            }
        }
    }

}

.main-canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    pointer-events: none;

    max-width: 100%;
    height: 100%;
}

#m-today-canvas {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;

    width: 100%;
}

.noscroll {
    overflow: hidden !important;
    height: 100vh !important;
}