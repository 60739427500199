@import "variables";
@import "blocks";


.m-about {

    position: relative;

    padding-bottom: 17vh;
    height: 100vh;

    &__path {

        position: absolute;
        top: 0;
        right: 0;

        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 30%;
        max-width: 436px;
        max-height: 35vh;
        height: 239px;

        svg,
        object {
            // width: 100%;
            height: auto;

            @media screen and (max-width: 575px) {
                width: 100%;
            }
        }



    }

    @media (min-aspect-ratio: ~'16/9') {
        padding-bottom: 10vh;
    }

    @media screen and (max-width: 991px) {
        height: auto;
        padding-bottom: 120px;
    }

    @media screen and (max-width: 767px) {
        padding: 60px 5% 84px;
        min-height: unset !important;
    }

    @media screen and (max-width: 575px) {
        padding: 40px 15px 136px;
        min-height: unset !important;
    }

    &__wrapper {
        position: relative;
        // display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10vh;

        @media (min-aspect-ratio: ~'16/9') and (min-height: 850px) {
            margin-top: 12vh;
        }

        @media (min-aspect-ratio: ~'16/9') and (max-height: 849px) {
            // margin-top: 7vh;
            margin-top: 12vh;
        }

        @media screen and (max-width: 991px) {
            margin-top: 72px;
        }

        @media screen and (max-width: 767px) {
            margin-top: 36px;
        }

        @media screen and (max-width: 575px) {
            margin-top: 0;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 50px;
        z-index: 2;
        width: 36%;
        height: 50vh;
        // margin: 3vh 5vw 0 3vw;

        @media (min-aspect-ratio: ~'16/9') {
            width: 30%;

            height: 64vh;
        }

        @media screen and (max-width: 767px) {
            height: 300px;
        }

        @media screen and (max-width: 575px) {
            position: relative;
            left: -15px;
            width: calc(100% + 15px);
            margin: 0;
        }

        svg {
            filter: drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.25));
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: -37px;
            left: -45px;
            z-index: -1;
            display: block;
            width: 100%;
            height: calc(100% + 19px);
            background-color: rgba(243, 243, 243, 0.5);

            @media (min-aspect-ratio: ~'16/9') {
                top: -39px;
                left: -50px;
            }

            @media screen and (max-width: 767px) {
                top: -20px;
                left: -25px;
            }

            @media screen and (max-width: 575px) {
                top: 15px;
                left: -5px;
            }
        }

        &::before {
            top: -19px;
            left: -22px;
            height: 100%;
            z-index: -2;
            background: rgba(198, 200, 206, 0.5);

            @media (min-aspect-ratio: ~'16/9') {
                top: -20px;
                left: -27px;
            }

            @media screen and (max-width: 767px) {
                top: -10px;
                left: -12px;
            }

            @media screen and (max-width: 575px) {
                top: 15px;
                left: -5px;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        width: 50%;
        margin-left: 41%;
        text-align: center;
        padding-top: 7vh;

        @media (min-aspect-ratio: ~'16/9') {
            width: 35vw;
            max-width: 600px;
            margin-left: 42%;

        }

        @media (min-aspect-ratio: ~'16/9') and (min-height: 850px) {
            padding-top: 8vh;
        }

        @media screen and (max-width: 991px) {
            margin-left: 49%;
            padding-top: 60px;
        }

        @media screen and (max-width: 767px) {
            padding-top: 36px;
        }

        @media screen and (max-width: 575px) {
            width: 100%;
            margin: 0 0 46px;
        }

        .title-big {
            margin-bottom: 3vh;

            @media (min-aspect-ratio: ~'16/9') and (max-height: 849px) {
                margin-bottom: 2vh;
            }

            @media screen and (max-width: 767px) {
                margin-top: 18px;
            }

            @media screen and (max-width: 575px) {
                margin: 0 0 16px;
            }
        }

        * {
            color: @blue;
        }
    }

    &__link {
        position: absolute;
        top: 100%;
        right: calc(9% + 60px);

        transform: translateY(22vh);

        @media (min-aspect-ratio: ~'16/9') {
            transform: translateY(16vh);
        }

        @media screen and (max-width: 767px) {
            right: calc(5%);

            transform: translateY(24px);
        }

        @media screen and (max-width: 575px) {
            right: 42px;
            transform: translateY(46px);
        }

    }
}

.m-today {
    position: relative;
    background: #C6C8CE;
    overflow: hidden;

    @media screen and (max-width: 991px) {
        padding-bottom: 0px;
        min-height: auto !important;
    }

    &__logo {
        position: absolute;
        top: 0;
        right: 3vw;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 11vw;
        height: 11vw;
        max-width: 220px;
        max-height: 220px;

        img {
            width: 75%;
            height: 75%;
            animation: rotate 10s linear infinite;
        }

        @media screen and (max-width: 991px) {
            width: 150px;
            height: 150px;
        }
    }

    &__list {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-column-gap: 10%;
        margin-top: 9vh;

        @media (min-aspect-ratio: ~'16/9') {
            max-width: 1130px;
            margin-top: 7vh;
        }

        @media screen and (max-width: 991px) {
            margin-top: 60px;

            display: flex;
            flex-direction: column;
        }

        @media screen and (max-width: 767px) {
            margin-top: 36px;
        }

    }

    &__item {

        border-top: 2px dashed #07174A;
        padding-bottom: 20px;
        opacity: 0;
        transform: translateY(50%);



        @media screen and (max-width: 991px) {}

        @media (min-aspect-ratio: ~'16/9') {
            padding-top: 1vh;
            padding-bottom: 1vh;
        }

        @media screen and (max-width: 575px) {
            padding-top: 20px;

            &:first-child {
                border-top: none;
            }
        }

        &-inner {
            display: flex;
            align-items: center;

            @media screen and (max-width: 991px) {
                display: grid;
                grid-template-columns: 1fr 2fr 4fr;
            }

            @media screen and (max-width: 575px) {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .heading-alt {
            min-width: 133px;
            margin-right: 20px;
            color: white;

            @media screen and (max-width: 991px) {
                min-width: unset;
                width: auto;

            }

            @media screen and (max-width: 575px) {
                width: 30%;
                margin: 0;
            }

        }


        .text {
            max-width: 230px;

            @media screen and (max-width: 991px) {
                max-width: 100%;
            }

            @media screen and (max-width: 575px) {
                width: 100%;
                margin-top: 20px;

            }
        }

        .text_white {
            width: 100px;
            margin-right: 26px;
            color: white;

            @media screen and (max-width: 991px) {
                width: auto;
            }

            @media screen and (max-width: 575px) {
                width: 30%;
                margin: 0;
            }

        }

    }

    &__next {

        position: absolute;
        bottom: 0;
        z-index: 9999;

        left: 45%;
        width: 55%;
        padding: 5vh 30px 4vh 60px;
        text-align: center;

        @media (min-aspect-ratio: ~'16/9') {
            width: 40%;
            left: 60%;
            padding: 4vh 1vw 3vh 2vw;
        }

        @media screen and (max-width: 1366px) {
            padding: 25px 50px 18px 70px;
            width: 45%;
            left: 55%;
        }

        @media screen and (max-width: 991px) {
            height: auto;
            padding: 30px 30px 30px 40px;
        }

        @media screen and (max-width: 991px) {
            position: relative;
            // left: -55px;
            left: -30px;
            width: calc(~"100% + 60px");
            padding: 30px 45px 30px 30px;
            border-top: 2px dashed #FFAB37;
            border-top-left-radius: 60px;
            background: @white;

            img{
                opacity: 1 !important;
                visibility: visible !important;
                right: 0;
                top: 0;

                transform: translate(50%, -50%) rotate(180deg);

                animation: shipMoveHorizontaly 7s linear;
                animation-iteration-count: infinite;
            }
        }

        @media screen and (max-width: 575px) {
            
        }

        

        canvas{
            z-index: -1;
        }

        svg,
        object {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: auto;
            min-height: 100%;


            @media screen and (max-width: 1199px) {
                width: auto;
                height: auto;
                min-height: 120%;
                min-width: 130%;
            }

            @media screen and (max-width: 991px) {
                left: -4vw;
                // width: calc(100% + 20vw);
                // width: auto;
            }
        }
    }

    &.is-visible {
        .m-today__item {
            opacity: 1;
            transform: translateY(0);

            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

            &:nth-child(1),
            &:nth-child(4) {
                transition: transform 0.75s ease-in-out 0.5s, opacity 0.75s ease-in-out 0.5s;
            }

            &:nth-child(2),
            &:nth-child(5) {
                transition: transform 0.75s ease-in-out 1s, opacity 0.75s ease-in-out 1s;
            }

            &:nth-child(3),
            &:nth-child(6) {
                transition: transform 0.75s ease-in-out 1.5s, opacity 0.75s ease-in-out 1.5s;

            }
        }

        @media screen and (max-width: 768px) {
            .m-today__item {
                &:nth-child {
                    transition-delay: 0s !important;
                }

                // &:nth-child(2) {
                //     transition-delay: 1s;
                // }

                // &:nth-child(3) {
                //     transition-delay: 1.5s;
                // }

                // &:nth-child(4) {
                //     transition-delay: 2s;
                // }

                // &:nth-child(5) {
                //     transition-delay: 2.5s;
                // }

                // &:nth-child(6) {
                //     transition-delay: 3s;
                // }
            }
        }

        .m-today__next {
            opacity: 1;
            transition: opacity 0.5s ease 2s;
        }
    }

}

@keyframes shipMoveHorizontaly {
    0%{
        right: 0;
        top: 0;
        transform: translate(50%, -50%) rotate(180deg);
    }
    80%{
        top: 0;
        right: calc(~"100% - 45px");
        transform: translate(50%, -50%) rotate(180deg);
    }
    100%{
        right: 100%;
        top: 40px;
        transform: translate(0%, -50%) rotate(90deg);
    }
}

.m-activity {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 4vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 991px) {
        padding-bottom: 0;
    }

    &__title {
        padding: 0 2vw;

        @media screen and (max-width: 767px) {
            padding: 0 5%;
        }
    }

    &__list {
        position: relative;
        // margin-top: 6vh;
        min-height: 57%;
        padding-bottom: 2vh;

        @media (min-aspect-ratio: ~'16/9') {
            // margin-top: 5vh;
        }

        @media screen and (max-width: 991px) {
            margin-top: 40px;
        }

        background: #F3F3F3;
        background-position-y: 200px;


    }

    &__item {
        width: 54%;
        margin: 0 0 0 auto;
        padding: 0 2vw;

        @media (min-aspect-ratio: ~'16/9') {
            width: 40%;
        }

        @media screen and (max-width: 1366px) {
            width: 44%;
            padding: 0 30px;
        }

        @media screen and (max-width: 991px) {
            width: 100%;
        }

        @media screen and (max-width: 575px) {
            padding: 0 15px;
        }

        &-top {
            position: relative;
            padding: 2vh 0 2vh 22px;

            cursor: pointer;

            @media screen and (max-width: 575px) {

                .title-alt {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
        }

        &-button {
            position: absolute;
            top: 50%;
            left: 0;

            transform: translate(0, -50%);
            width: 12px;
            height: 12px;

            cursor: pointer;


            // &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                height: 10px;
                width: 10px;

                border: 3px solid @yellow;
                border-left: none;
                border-top: none;

                border-radius: 3px;

                // background: @yellow;

                transform: translate(-50%, -65%) rotate(45deg);

                transition: 0.33s ease;

                @media screen and (max-width: 575px) {
                    // height: 19px;
                    // width: 2.5px;
                }
            }



            @media screen and (max-width: 575px) {
                height: 19px;

            }

        }

        &-img {
            position: absolute;
            left: 2.5vw;
            bottom: 2vh;

            width: 34vw;
            padding-top: 20vw;
            background: @white;

            opacity: 0;
            visibility: hidden;

            transition: 0.5s ease 0.5s;

            @media screen and (max-width: 991px) {
                position: relative;
                left: 0;

                width: 100%;
                height: 170px;
                margin-top: 24px;
                padding-top: 50%;
                background: transparent;

            }

            @media screen and (max-width: 575px) {
                height: auto;
                padding-top: 90%;

            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;

                transform: translate(-3%, -4%);

                @media screen and (max-width: 991px) {
                    transform: translate(0, 0);
                }
            }
        }

        &-bottom {
            max-height: 0;
            padding-left: 40px;
            overflow: hidden;

            transition: 0.5s ease;

            @media screen and (max-width: 991px) {
                padding-left: 0;
            }

            @media screen and (max-width: 575px) {
                margin-left: -15px;

                .text {
                    padding-left: 15px;
                }
            }

        }

        &.is-active {

            .m-activity__item-bottom {
                max-height: 100vh;
                transition: 1s ease 0.5s;
            }

            .m-activity__item-img {
                opacity: 1;
                visibility: visible;
            }

            .m-activity__item-button {
                &:after {
                    transform: translate(-50%, -50%) rotate(225deg);
                }
            }
        }
    }

    &__path {
        position: absolute;
        top: 0;
        left: 45%;
        bottom: 0;

        display: block;
        width: 55%;
        min-height: 100%;

        pointer-events: none;

        // border-top-left-radius: 120px;
       
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 9px;
            height: 100%;
            width: 2px;
            border-left: 2px dashed #FFAB37;
        }
        img{
            position: absolute;
            top: 0;
            left:10px;
            z-index: 2;

            transform: translate(-50%, -50%) rotate(90deg);

            animation: shipMove 15s linear ;
            animation-iteration-count: infinite;
        }

        svg,
        object {
            height: 100%;
        }

        @media (min-aspect-ratio: ~'16/9') {
            // left: calc(60% - 13px);
            left: 60%;
            width: 40%;
            // transform: translateX(-50%);
        }

        @media only screen and (max-width: 1366px) {
            left: 55%;
            width: 45%;
        }

        @media screen and (max-width: 991px) {
            display: none;
        }
    }
}

@keyframes shipMove {
    0%{
        top: 0;
    }
    100%{
        top: 100%;
    }
}

.m-cargo {
    position: relative;
    padding-right: 60%;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;

        width: 35%;
        height: 50%;
        background: url("../img/m-cargo-bg.png") no-repeat left bottom;
        background-size: contain;

        @media (min-aspect-ratio: ~'16/9') {
            width: 25%;
        }

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    @media (min-aspect-ratio: ~'16/9') {
        padding-right: 40%;
    }

    @media screen and (max-width: 991px) {
        padding-right: 5%;
        min-height: auto !important;

    }

    @media screen and (max-width: 575px) {
        padding-right: 15px;
    }

    &__title {
        margin-bottom: 13vh;

        @media (min-aspect-ratio: ~'16/9') {
            margin-bottom: 12vh;
        }

        @media screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
    }

    &__list {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 45%;
        z-index: 9999;

        width: 55%;
        padding: 17vh 4% 7vh 4%;

        @media (min-aspect-ratio: ~'16/9') {
            // left: calc(60% - 8px);
            // width: calc(40% + 8px);
            left: 60%;
            width: 40%;
            padding: 15vh 2% 7vh 3%;
        }

        @media screen and (max-width: 1366px) {
            left: 55%;
            width: 45%;
        }

        @media screen and (max-width: 991px) {
            position: static;
            left: 0;

            width: 100%;
            padding: 0;
            margin-top: 30px;
        }
    }

    &__path {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 45%;
        z-index: 1;

        // width: calc(55% + 6px);
        width: 55%;
        min-height: 100vh;

        img{
            position: absolute;
            top: 0;
            left:10px;
            z-index: 2;

            transform: translate(-50%, -50%) rotate(90deg);

            animation: shipMove 15s linear ;
            animation-iteration-count: infinite;
        }

        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 9px;
            height: 100%;
            width: 100%;
            border-left: 2px dashed #FFAB37;
            background: @white;
        }

        @media (min-aspect-ratio: ~'16/9') {
            left: 60%;
            width: 40%;
        }

        @media screen and (max-width: 1366px) {
       

            // object {
            //     width: auto;
            //     height: auto;
            //     min-width: 100%;
            //     min-height: 100%;
            // }

            left: 55%;
            width: 45%;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                width: 50%;
                background: @white;
            }
        }

        @media screen and (max-width: 991px) {
            display: none;
        }


    }

    &__item {
        margin-bottom: 6vh;
        position: relative;

        opacity: 0;
        transform: translateY(50%);


        &-title {
            position: relative;

            margin-bottom: 1em;
            transition: 0.25s ease;

            padding-right: 56px;

            span {
                display: inline-block;
                padding-right: 20px;
                position: relative;
                z-index: 1;
                background: @white;

                @media screen and (max-width: 991px) {
                    background: #F3F3F3;
                }

                @media screen and (max-width: 575px) {
                    background: @nocolor;
                    padding-bottom: 8px;

                }
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                right: 56px;


                display: block;

                transform: translateY(-50%);
            }

            &::before {
                height: 2px;
                border-top: 2px dashed #C6C8CE;

                @media screen and (max-width: 575px) {
                    top: 100%;


                }
            }

            &::after {
                left: unset;
                right: 0;
                width: 41px;
                height: 16px;
                background: url('../img/yellow-arrow.svg') no-repeat top center;
                background-size: contain;
                transform: translateY(-60%);

                @media screen and (max-width: 575px) {
                    width: 25px;
                    height: 15px;
                }
            }
        }

        &:hover {
            .m-cargo__item-title {
                color: @yellow;
            }
        }
    }

    &.is-visible {
        .m-cargo__item {
            opacity: 1;
            transform: translateY(0%);

            transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;

            &:nth-child(1) {
                transition: opacity 0.75s ease-in-out 0.5s, transform 0.75s ease-in-out 0.5s;
            }


            &:nth-child(2) {
                // transition-delay: 1s;
                transition: opacity 0.75s ease-in-out 1s, transform 0.75s ease-in-out 1s;
            }

            &:nth-child(3) {
                // transition-delay: 1.5s;
                transition: opacity 0.75s ease-in-out 1.5s, transform 0.75s ease-in-out 1.5s;
            }


            &:nth-child(4) {
                transition-delay: 2s;
                transition: opacity 0.75s ease-in-out 2s, transform 0.75s ease-in-out 2s;
            }
        }

        @media screen and (max-width: 768px) {
            .m-cargo__item {
                &:nth-child {
                    transition-delay: 0s !important;
                }
            }
        }

        .m-today__next {
            opacity: 1;
            transition: opacity 0.5s ease 2s;
        }
    }
}

.m-geography {
    position: relative;
    min-height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 1199px) {
        height: auto;
        min-height: 100vh;
    }



    &__path {
        position: absolute;
        top: 0;
        right: 0;

        height: auto;
        padding-top: 12%;
        width: 55%;

        @media (min-aspect-ratio: ~"16/9") {
            width: 40%;
        }

        @media screen and (max-width: 1366px) {
            width: 45%;
            left: 55%;
            padding-top: 0;
            min-height: 23%;
        }

        @media screen and (max-width: 991px) {
            padding-top: 25%;
        }

        @media screen and (max-width: 575px) {
            width: 100%;
            padding-top: 50%;
            min-height: unset;
            right: unset;
            left: 50%;
        }

        svg,
        object {
            position: absolute;
            top: 0;
            right: 0;

            width: 100%;
            // height: 100%;

            // display: none;

            @media screen and (max-width: 991px) {
                display: block;
            }

            @media screen and (max-width: 991px) {
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    &__top {
        background: #C6C8CE;
        height: 52vh;

        // @media (min-aspect-ratio: ~'16/9') {
        //     height: 56vh;
        // }

        @media (min-aspect-ratio: ~"16/9") {
            padding-top: 5vh;
        }



        @media screen and (max-width: 1199px) {
            height: auto;
            min-height: 52vh !important;
            padding-bottom: 3vh;
            padding-top: 7vh;
        }

        @media screen and (max-width: 767px) {
            padding: 30px 5% 90px;
        }

        @media screen and (max-width: 575px) {
            position: relative;
        }

        .section-title {
            color: @white;

            span {
                -webkit-text-stroke-color: @white;
                color: #C6C8CE;
            }
        }
    }

    &__carousel {
        position: relative;
        margin-top: 60px;

        @media screen and (max-width: 575px) {
            position: static;
        }
    }

    &__list {
        display: flex;
        overflow: hidden;
        margin-top: 6vh;

        @media (min-aspect-ratio: ~"16/9") {
            margin-top: 4vh;
        }
    }

    &__item {
        width: 20%;

        @media (min-aspect-ratio: ~"16/9") {
            width: 14%;
        }

        &-title {
            position: relative;
            margin-bottom: 1.2vh;
            font-size: 1.2em;

            @media (min-aspect-ratio: ~"16/9") {
                margin-bottom: 1.5vh;
            }

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;

                width: 100%;
                height: 1px;
                transform: translateY(1.5vh);
            }
        }

        &-list {
            @media screen and (max-width: 575px) {
                padding-top: 20px;
                margin-right: 30px;
                border-top: 1px solid @white;

                li {
                    margin-bottom: 16px;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
    }

    &__nav {
        position: absolute;
        top: -4vh;
        right: 0;

        transform: translateY(-100%);

        display: flex;
        align-items: center;
        justify-content: center;
        // width: 120px;
        // height: 30px;
        // background: #FFFFFF;

        top: 50%;
        transform: translateY(-50%);

        &-pages {
            display: flex;
            align-items: center;
            margin-right: 17px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.05em;

            color: #FFAB37;

            div {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.05em;

                color: #FFAB37;
            }
        }

        &-button {
            cursor: pointer;
        }

        @media screen and (max-width: 575px) {
            top: unset;
            right: 15px;
            bottom: 40px;

            transform: none;
        }
    }

    &__bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 48vh;

        background: #f3f3f3;

        @media screen and (max-width: 1199px) {
            position: relative;
            height: auto;
            min-height: 48vh;
        }

        @media screen and (max-width: 767px) {
            min-height: unset;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0%, -50%);
            width: auto;
            height: 100%;


            @media screen and (max-width: 1199px) {
                position: relative;
                transform: none;
            }

            @media screen and (max-width: 767px) {
                max-width: 150%;
                height: auto;
            }
        }

        &-ukr {
            position: absolute;
            top: 0;
            right: 3vw;

            height: 39vh;
            width: 39vh;


            transform: translateY(-10%);

            @media screen and (max-width: 991px) {
                right: unset;
                left: 30px;
            }

            @media screen and (max-width: 991px) {
                right: unset;
                left: 30px;

                width: 180px;
                height: 180px;

                transform: translateY(-50%);

            }

            @media screen and (max-width: 575px) {
                left: 0;
                width: 140px;
                height: 140px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.a-mission {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 3vh 0 0;
    background: url("../img/bg1.jpg") no-repeat bottom center;
    background-size: 100%;

    @media screen and (max-width: 1199px) {
        height: auto !important;
        min-height: unset;
    }

    @media screen and (max-width: 991px) {
        padding: 40px 0 0;
    }

    @media screen and (max-width: 767px) {
        padding: 40px 0 0;
    }

    &__title {
        padding-left: 2vw;

        @media screen and (max-width: 991px) {
            padding-left: 30px;
        }
    }

    &__wrapper {

        position: relative;
        height: 100%;

    }

    &__description {
        position: absolute;
        top: 50%;
        left: 3vw;
        padding: 7.5vh 29% 4vh 30px;

        transform: translateY(-50%);
        background: linear-gradient(89.94deg, #07174A -1.97%, rgba(7, 23, 74, 0) 98.41%);

        @media screen and (max-width: 1199px) {
            position: relative;
            margin: 120px 0 160px;
            transform: none;
        }

        @media screen and (max-width: 767px) {
            left: 0;
            margin: 15px 0 200px 0px;
            padding: 35px 30px 25px 30px;
            background: linear-gradient(89.94deg, #07174A -1.97%, rgba(7, 23, 74, 0.5) 98.41%);
        }

        &::before {
            content: '';
            position: absolute;
            top: 4.5vh;
            left: 30px;

            display: block;
            width: 164px;
            height: 3px;

            background: @white;

            @media screen and (max-width: 767px) {
                top: 25px;
                height: 1px;
            }
        }
    }

    &__text {
        max-width: 588px;
        color: @white;

        &>* {
            margin-bottom: 0.5em;
        }

        @media screen and (max-width: 767px) {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            &>* {
                margin-bottom: 0.2em;
            }
        }
    }
}

.canvas-main[resize]{
    width: 100%;
    height: 100%;
}

@import "parts/contacts";

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}


//scroll please icon

.mouse {
    position: fixed;
    bottom: 30px;
    left: 56%;
    z-index: 9;

    @media screen and (max-width: 1199px) {
        display: none;
    }

    &.hidden {
        display: none;
    }
}

.mouseScrollDown {
    background: @yellow -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), color-stop(50%, #ffffff), to(#ffffff));
    background: @yellow linear-gradient(transparent 0%, transparent 50%, #ffffff 50%, #ffffff 100%);
    position: relative;
    width: 26px;
    height: 39px;
    border-radius: 100px;
    background-size: 225%;
    cursor: pointer;
    opacity: .65;
    -webkit-animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
    animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}

.mouseScrollDown:before,
.mouseScrollDown:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.mouseScrollDown:before {
    width: 22px;
    height: 35px;
    background-color: #c2c1cb;
    border-radius: 100px;
}

.mouseScrollDown:after {
    background-color: @yellow;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    -webkit-animation: trackBallSlide 5s linear infinite;
    animation: trackBallSlide 5s linear infinite;
}

@-webkit-keyframes colorSlide {
    0% {
        background-position: 0% 100%;
    }

    20% {
        background-position: 0% 0%;
    }

    21% {
        background-color: @yellow;
    }

    29.99% {
        background-color: #ffffff;
        background-position: 0% 0%;
    }

    30% {
        background-color: @yellow;
        background-position: 0% 100%;
    }

    50% {
        background-position: 0% 0%;
    }

    51% {
        background-color: @yellow;
    }

    59.99% {
        background-color: #ffffff;
        background-position: 0% 0%;
    }

    60% {
        background-color: @yellow;
        background-position: 0% 100%;
    }

    80% {
        background-position: 0% 0%;
    }

    81% {
        background-color: @yellow;
    }

    89.99%,
    100% {
        background-color: #ffffff;
    }
}

@keyframes colorSlide {
    0% {
        background-position: 0% 100%;
    }

    20% {
        background-position: 0% 0%;
    }

    21% {
        background-color: @yellow;
    }

    29.99% {
        background-color: #ffffff;
        background-position: 0% 0%;
    }

    30% {
        background-color: @yellow;
        background-position: 0% 100%;
    }

    50% {
        background-position: 0% 0%;
    }

    51% {
        background-color: @yellow;
    }

    59.99% {
        background-color: #ffffff;
        background-position: 0% 0%;
    }

    60% {
        background-color: @yellow;
        background-position: 0% 100%;
    }

    80% {
        background-position: 0% 0%;
    }

    81% {
        background-color: @yellow;
    }

    89.99%,
    100% {
        background-color: #ffffff;
    }
}

@-webkit-keyframes trackBallSlide {
    0% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    6% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(2.5px);
        transform: scale(0.9) translateY(2.5px);
    }

    14% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(20px);
        transform: scale(0.4) translateY(20px);
    }

    15%,
    19% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-10px);
        transform: scale(0.4) translateY(-10px);
    }

    28%,
    29.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    30% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    36% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(2.5px);
        transform: scale(0.9) translateY(2.5px);
    }

    44% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(20px);
        transform: scale(0.4) translateY(20px);
    }

    45%,
    49% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-10px);
        transform: scale(0.4) translateY(-10px);
    }

    58%,
    59.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    66% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(2.5px);
        transform: scale(0.9) translateY(2.5px);
    }

    74% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(20px);
        transform: scale(0.4) translateY(20px);
    }

    75%,
    79% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-10px);
        transform: scale(0.4) translateY(-10px);
    }

    88%,
    100% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }
}

@keyframes trackBallSlide {
    0% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    6% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(2.5px);
        transform: scale(0.9) translateY(2.5px);
    }

    14% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(20px);
        transform: scale(0.4) translateY(20px);
    }

    15%,
    19% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-10px);
        transform: scale(0.4) translateY(-10px);
    }

    28%,
    29.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    30% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    36% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(2.5px);
        transform: scale(0.9) translateY(2.5px);
    }

    44% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(20px);
        transform: scale(0.4) translateY(20px);
    }

    45%,
    49% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-10px);
        transform: scale(0.4) translateY(-10px);
    }

    58%,
    59.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }

    66% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(2.5px);
        transform: scale(0.9) translateY(2.5px);
    }

    74% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(20px);
        transform: scale(0.4) translateY(20px);
    }

    75%,
    79% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-10px);
        transform: scale(0.4) translateY(-10px);
    }

    88%,
    100% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-10px);
        transform: scale(1) translateY(-10px);
    }
}

@-webkit-keyframes nudgeMouse {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    30% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    60% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    80% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes nudgeMouse {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    30% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    60% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    80% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}