/* fonts */
/*color
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.preloader.is-hidden .preloader__img img {
  opacity: 0;
  transition: 0.5s ease;
}
.preloader__img img {
  width: 200px;
  height: 70px;
  max-width: 80%;
}
.preloader__timeline {
  display: none !important;
}
@keyframes gradient {
  0% {
    visibility: hidden;
    background-position: 110% 0%;
  }
  50% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
    background-position: 0% 110%;
  }
}
canvas {
  max-width: 100vw;
}
body {
  font-family: 'Montserrat', serif;
  font-weight: normal;
  color: #030B27;
  font-size: 18px;
  line-height: 22px;
}
@media screen and (max-height: 850px) {
  body {
    font-size: 15px;
  }
}
@media screen and (max-height: 690px) {
  body {
    font-size: 14px;
  }
}
@media screen and (max-width: 1199px) {
  body {
    font-size: 16px;
  }
}
@media screen and (max-width: 1199px) {
  html {
    overflow-y: visible;
    max-width: 100%;
  }
}
.mainview {
  font-size: 18px;
}
@media screen and (max-height: 850px) {
  .mainview {
    font-size: 15px;
  }
}
@media screen and (max-height: 690px) {
  .mainview {
    font-size: 14px;
  }
}
@media screen and (max-width: 1199px) {
  .mainview {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  .mainview {
    font-size: 15px !important;
  }
}
.mainbag {
  max-width: 83vw;
}
@media screen and (max-width: 1199px) {
  .mainbag {
    max-width: 100vw;
  }
}
a {
  text-decoration: none;
}
a:hover {
  color: #ffab37;
}
li {
  list-style: none;
}
.heading {
  font-weight: bold;
  font-size: 4.45em;
  line-height: 1;
  color: #030B27;
}
.heading_white {
  color: #ffffff;
}
@media screen and (max-height: 850px) {
  .heading {
    font-size: 4em;
  }
}
@media screen and (max-height: 690px) {
  .heading {
    font-size: 3.5em;
  }
}
@media screen and (max-width: 991px) {
  .heading {
    font-size: 48px;
    line-height: 1;
  }
}
@media screen and (max-width: 767px) {
  .heading {
    font-size: 36px;
    line-height: 44px;
  }
}
@media screen and (max-width: 575px) {
  .heading {
    font-size: 28px;
    line-height: 34px;
  }
}
.heading-alt {
  font-family: 'Montserrat Alternates', serif;
  font-size: 4em;
  line-height: 1;
  color: #030B27;
}
.heading-alt_white {
  color: #ffffff;
}
@media screen and (max-height: 850px) {
  .heading-alt {
    font-size: 3.5em;
  }
}
@media screen and (max-height: 690px) {
  .heading-alt {
    font-size: 3em;
  }
}
@media screen and (max-width: 991px) {
  .heading-alt {
    font-size: 50px;
    line-height: 54px;
  }
}
@media screen and (max-width: 767px) {
  .heading-alt {
    font-size: 38px;
    line-height: 42px;
  }
}
.section-title > div {
  overflow: hidden;
  padding-bottom: 5px;
}
.section-title > div span,
.section-title > div b,
.section-title > div div {
  display: block;
  transform: translateY(300%) skewY(10deg);
}
.section-title span {
  color: transparent;
  -webkit-text-stroke: 2px #030B27;
  font-family: 'Montserrat Alternates', serif;
  font-size: 0.9em;
  font-weight: 600;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .section-title span {
    font-size: 36px;
    line-height: 44px;
    -webkit-text-stroke: 1px #030B27;
  }
}
.section-title_white span {
  -webkit-text-stroke: 2px #ffffff;
}
.section-title b {
  font-family: 'Montserrat', serif;
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1;
  color: transparent;
  -webkit-text-stroke: 2px #C6C8CE;
}
@media only screen and (max-width: 767px) {
  .section-title b {
    font-size: 36px;
    line-height: 44px;
    -webkit-text-stroke: 1px #C6C8CE;
  }
}
.section-title_blue b {
  -webkit-text-stroke: 2px #07174A;
}
@media only screen and (max-width: 767px) {
  .section-title_blue b {
    -webkit-text-stroke: 1px #07174A;
  }
}
.is-visible .section-title > div span,
.is-visible .section-title > div b,
.is-visible .section-title > div div {
  transform: translateY(0px) skewY(0deg);
  transition: transform 1s ease-in-out;
}
.title {
  font-weight: 800;
  font-size: 1em;
  line-height: 1.2;
  color: #030B27;
}
.title_white {
  color: #ffffff;
}
@media screen and (max-width: 991px) {
  .title {
    font-size: 18px;
    line-height: 1;
  }
}
.title-alt {
  font-family: 'Montserrat Alternates', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.33em;
  line-height: 1.2;
  color: #030B27;
}
@media screen and (max-width: 1366px) {
  .title-alt {
    font-size: 1.25em;
  }
}
@media screen and (max-height: 850px) {
  .title-alt {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 991px) {
  .title-alt {
    font-size: 20px;
    line-height: 1;
  }
}
@media screen and (max-width: 767px) {
  .title-alt {
    font-size: 18px;
    line-height: 22px;
  }
}
@media screen and (max-width: 575px) {
  .title-alt {
    font-size: 16px;
    line-height: 19px;
  }
}
.title-alt_white {
  color: #ffffff;
}
.title-alt strong {
  font-size: 1.5em;
  line-height: 122%;
}
@media screen and (max-width: 767px) {
  .title-alt strong {
    font-size: 24px;
    line-height: 29px;
  }
}
.title-alt b {
  color: #ffab37;
}
.title-light {
  font-weight: 300;
  font-size: 1.4em;
  font-size: 1.7em;
  color: #030B27;
}
@media screen and (max-height: 850px) {
  .title-light {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 991px) {
  .title-light {
    font-size: 20px;
    line-height: 1;
  }
}
.title-big {
  font-family: 'Montserrat Alternates', serif;
  font-style: normal;
  font-weight: bold;
  color: #07174A;
  font-size: 2em;
  line-height: 1.2;
}
@media screen and (max-height: 690px) {
  .title-big {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 767px) {
  .title-big {
    font-size: 24px;
    line-height: 29px;
  }
}
@media screen and (max-width: 575px) {
  .title-big {
    font-size: 18px;
    line-height: 1;
  }
}
.title-big b {
  color: #ffab37;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.2;
}
@media screen and (max-width: 767px) {
  .title-big b {
    font-size: 24px;
    line-height: 29px;
  }
}
@media screen and (max-width: 575px) {
  .title-big b {
    font-size: 18px;
    line-height: 1;
  }
}
.text {
  font-weight: normal;
  font-size: 1em;
  line-height: 1.2;
  color: #030B27;
}
.text > * {
  margin-bottom: 0.7em;
}
.text > *:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .text {
    font-size: 16px;
    line-height: 19px;
  }
}
.text_yellow {
  color: #ffab37;
}
.text li {
  margin-bottom: 0.3em;
  line-height: 1.2;
}
.btn {
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #000000;
  font-size: 0.77em;
  line-height: 1;
}
@media (min-aspect-ratio: 16/9) {
  .btn {
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 991px) {
  .btn {
    font-size: 14px;
  }
}
.button {
  display: block;
  width: 207px;
  height: 40px;
  background: #f3f3f3;
  border: none;
  font-family: 'Montserrat Alternates', serif;
  font-weight: normal;
  font-size: 0.77em;
  line-height: 40px;
  color: #07174A;
  text-align: center;
  text-decoration: none;
  transition: 0.33s ease;
}
.button:hover {
  background: #07174A;
  color: #f3f3f3;
}
@media screen and (max-width: 575px) {
  .button {
    font-size: 14px;
    color: #9EA1A9;
  }
}
.text-small {
  font-weight: normal;
  font-size: 0.77em;
  line-height: 1em;
}
.text-small b {
  font-weight: normal;
  color: #ffab37;
}
.link-arrow {
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #000000;
  font-size: 0.77em;
  line-height: 1;
  color: #07174A;
  position: relative;
  border-bottom: 2px solid #07174A;
}
@media (min-aspect-ratio: 16/9) {
  .link-arrow {
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 991px) {
  .link-arrow {
    font-size: 14px;
  }
}
.link-arrow:after,
.link-arrow::before {
  content: '';
  position: absolute;
  right: -19px;
  top: 50%;
  transform: translate(100%, -50%);
  display: block;
  width: 20px;
  height: 16px;
  background: url("../img/icons/link-arrow.svg") no-repeat center center;
  background-size: contain;
  transition: 0.33s ease;
}
.link-arrow::before {
  background-image: url("../img/icons/link-arrow-yellow.svg");
  opacity: 1;
  visibility: hidden;
}
.link-arrow:hover {
  color: inherit;
}
.link-arrow:hover::after {
  opacity: 0;
  visibility: hidden;
  transition: 0.33s ease;
}
.link-arrow:hover::before {
  opacity: 1;
  visibility: visible;
  transition: 0.33s ease;
}
.page-wrap {
  position: relative;
  padding-left: 17vw;
  overflow: hidden;
  max-width: 100vw;
}
@media screen and (max-width: 1199px) {
  .page-wrap {
    padding-left: 0;
  }
}
.page-wrap.scrolled {
  overflow-y: scroll;
}
.page-wrap.scrolled .mainbag {
  overflow-y: scroll;
  height: auto;
}
.page-wrap.scrolled .p-100 {
  height: auto;
  min-height: 100vh;
}
@media screen and (max-width: 767px) {
  .page-wrap.scrolled .p-100 {
    min-height: auto;
    width: 100%;
    overflow: hidden;
  }
}
body.scrolled {
  overflow: scroll;
}
.grey-bg {
  background: #F3F3F3;
}
.darkgrey-bg {
  background: #C6C8CE;
}
.dark-bg {
  background: #030B27;
}
.yellow-bg {
  background: #ffab37;
}
/* section padding  */
.p-100 {
  height: 100vh;
  padding: 11vh 2vw 1vh;
}
@media (min-aspect-ratio: 16/9) and (min-height: 850px) {
  .p-100 {
    padding: 7vh 2vw 1vh;
  }
}
@media (min-aspect-ratio: 16/9) and (max-height: 849px) {
  .p-100 {
    padding: 5vh 2vw 1vh;
  }
}
@media screen and (max-width: 991px) and (max-height: 600px) {
  .p-100 {
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .p-100 {
    padding: 48px 30px;
  }
}
@media screen and (max-width: 575px) {
  .p-100 {
    padding: 40px 15px;
  }
}
/* header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 17vw;
  min-width: 215px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 3vh 1.2vw 10vh;
  transition: 0.5s linear;
  z-index: 99;
}
@media screen and (max-width: 1440px) {
  .header {
    padding: 3vh 10px 10vh;
  }
}
@media screen and (max-width: 1199px) {
  .header {
    z-index: 9999999;
    width: 100%;
    height: auto;
    justify-content: center;
    padding: 8px 30px;
  }
}
@media screen and (max-width: 767px) {
  .header {
    height: 56px;
    padding: 8px 16px;
  }
}
@media screen and (max-width: 1199px) {
  .header__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 120px;
    margin: 0 auto;
  }
}
.header__logo-link {
  transition: 0.5s linear;
  font-size: 18px;
}
.header__logo-link:hover {
  color: inherit;
}
.header__logo-link:hover span {
  color: #ffab37;
}
@media screen and (max-width: 1199px) {
  .header__logo-link {
    text-align: center;
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .header__logo-link {
    font-size: 14px;
  }
}
.header__logo-img {
  position: relative;
  width: 100%;
  height: 2.7vh;
  min-height: 24px;
  margin-bottom: 6px;
}
@media screen and (max-width: 1199px) {
  .header__logo-img {
    width: 120px;
    height: auto;
    min-height: 20px;
    margin-bottom: 6px;
  }
}
.header__logo-img .white {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s linear;
}
.header__logo-img svg,
.header__logo-img img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition: 0.5s linear;
}
.header__logo-img svg path,
.header__logo-img img path {
  transition: 0.5s linear;
}
.header__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 4vh;
}
@media screen and (max-width: 1199px) {
  .header__content {
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    z-index: -1;
    transform: translateY(-150%);
    overflow: scroll;
    background: #ffffff;
    width: 100%;
    height: calc(100% - 56px);
    margin: 0;
    padding: 36px 30px;
    transition: 0.6s ease;
  }
  .header__content.is-active {
    transform: translateY(0);
  }
}
@media screen and (max-width: 767px) {
  .header__content {
    padding: 24px 15px;
  }
}
.header__nav-list {
  margin-bottom: 3vh;
}
@media screen and (max-width: 1199px) {
  .header__nav-list {
    margin-bottom: 36px;
  }
}
.header__nav-sublist {
  margin: 1.6vh 0;
  padding-left: 16px;
}
@media screen and (max-width: 1199px) {
  .header__nav-sublist {
    margin: 12px 0;
  }
}
.header__nav-sublist .header__nav-item a {
  position: relative;
}
.header__nav-sublist .header__nav-item.is-active a:before {
  background: #ffab37;
}
.header__nav-item {
  position: relative;
  margin-bottom: 1vh;
}
@media screen and (max-width: 1366px) {
  .header__nav-item {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1199px) {
  .header__nav-item {
    margin-bottom: 1px;
  }
}
.header__nav-item a {
  display: inline-block;
  position: relative;
  color: #030B27;
  padding-bottom: 2px;
  overflow: hidden;
  transition: 0.25s linear;
}
.header__nav-item a span {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
@media screen and (max-width: 1440px) {
  .header__nav-item a span {
    font-size: 17px;
  }
}
@media screen and (max-width: 1366px) {
  .header__nav-item a span {
    font-size: 15px;
  }
}
@media screen and (max-width: 1199px) {
  .header__nav-item a span {
    position: unset;
    white-space: unset;
  }
}
.header__nav-item a span::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: -2px;
  display: block;
  width: 100%;
  height: 2px;
  background: #ffab37;
  transform: translateX(-100%);
  opacity: 1;
  transition: 0.4s ease;
}
.header__nav-item a:hover span::before {
  transform: translateX(0%);
  opacity: 1;
}
.header__nav-item.is-active a {
  color: #ffab37;
  font-weight: 800;
}
.header__nav-item.is-active a:before {
  transform: translateX(0%);
  opacity: 1;
}
.header__nav-item.is-active a .header__nav-sublist .header__nav-item a {
  color: #ffab37;
}
.header__nav-item.is-active a .header__nav-sublist .header__nav-item a::before {
  background: #ffab37;
}
.header__nav-item_parent > a {
  padding-right: 15px;
}
.header__nav-item_parent > a:before {
  top: 50%;
  left: unset;
  right: 2px;
  width: 5px;
  height: 5px;
  border: 1.5px solid #030B27;
  border-top: 0;
  border-left: 0;
  background: transparent;
  transform: translateY(-50%) rotate(45deg);
  opacity: 1;
}
.header__nav-item_parent > a:hover:before {
  transform: translateY(-50%) rotate(45deg);
}
.header__lang {
  margin-bottom: 5vh;
  margin-top: 2vh;
}
@media screen and (max-width: 1199px) {
  .header__lang {
    margin-bottom: 48px;
    margin-top: 24px;
  }
}
.header__contacts-title {
  position: relative;
  padding-left: 30px;
  margin-bottom: 1.6vh;
  transition: 0.24s linear;
}
@media screen and (max-width: 1199px) {
  .header__contacts-title {
    margin-bottom: 24px;
  }
}
.header__contacts-title:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  width: 20px;
  height: 20px;
  background: url("../img/icons/phone.svg") no-repeat center center;
  background-size: contain;
  transition: 0.33s ease;
}
.header__contacts a {
  display: block;
  margin-bottom: 5px;
  color: #07174A;
  transition: 0.25s linear;
}
.header__contacts a:hover {
  color: #ffab37;
}
.header__burger {
  display: none;
}
@media screen and (max-width: 1199px) {
  .header__burger {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    display: block;
    width: 36px;
    height: 15px;
    min-height: 15px;
  }
}
@media screen and (max-width: 767px) {
  .header__burger {
    left: 13px;
  }
}
.header__burger span {
  position: absolute;
  top: 0;
  display: block;
  width: 36px;
  height: 3px;
  background: #07174A;
  border-radius: 10px;
  opacity: 1;
  transition: transform 0.25s ease, top 0.25s ease 0.35s, max-width 0.25s ease 0.6s;
}
.header__burger span:nth-child(2) {
  max-width: 60%;
  top: 50%;
  transform: translateY(-25%);
  transition: opacity 0.1s ease 0.15s, top 0.25s ease 0.35s, max-width 0.25s ease 0.7s;
}
.header__burger span:nth-child(3) {
  max-width: 30%;
  top: 100%;
  transition: transform 0.25s ease, top 0.25s ease 0.35s, max-width 0.25s ease 0.6s;
}
.header__burger.is-active span {
  max-width: 100%;
  top: 50%;
  transition: max-width 0.25s ease, top 0.25s ease 0.4s, transform 0.25s ease 0.6s;
}
.header__burger.is-active span:nth-child(1),
.header__burger.is-active span:nth-child(3) {
  transform: rotate(45deg);
}
.header__burger.is-active span:nth-child(2) {
  opacity: 0;
  transition: max-width 0.25s ease, opacity 0.1s ease 0.5s;
}
.header__burger.is-active span:nth-child(3) {
  transform: rotate(-45deg);
  transition: max-width 0.25s ease 0.125s, top 0.25s ease 0.4s, transform 0.25s ease 0.6s;
}
.header .lang-switcher__list-item a {
  color: #030B27;
}
.header .lang-switcher__list-item a:hover {
  color: #ffab37;
}
.header.is-contrasted {
  background: #030B27;
}
.header.is-contrasted .header__logo .white {
  opacity: 1;
  visibility: visible;
}
.header.is-contrasted .header__logo .blue {
  opacity: 0;
  visibility: hidden;
}
.header.is-contrasted .header__logo-link {
  color: #ffffff;
}
.header.is-contrasted .header__logo path {
  fill: #FFFFFF;
}
.header.is-contrasted .header__nav-item a {
  color: #ffffff;
}
@media screen and (max-width: 1199px) {
  .header.is-contrasted .header__nav-item a {
    color: #070606;
  }
}
.header.is-contrasted .header__nav-item.is-active a {
  color: #ffab37;
}
.header.is-contrasted .header__nav-search {
  border-bottom-color: #ffffff;
}
.header.is-contrasted .header__nav-search::before {
  background: url("../img/icons/search-white.svg");
}
.header.is-contrasted .header__lang {
  color: #ffffff;
}
.header.is-contrasted .header__lang .lang-switcher__icon path {
  fill: #FFFFFF;
}
.header.is-contrasted .header__lang .lang-switcher__langs:after {
  border-color: #ffffff transparent transparent transparent;
}
.header.is-contrasted .header__lang .lang-switcher__list-item:after {
  background: #ffffff;
}
.header.is-contrasted .header__lang .lang-switcher__list-item a,
.header.is-contrasted .header__lang .lang-switcher__list-item span {
  color: #ffffff;
}
.header.is-contrasted .header__lang .lang-switcher__list-item a:hover,
.header.is-contrasted .header__lang .lang-switcher__list-item span:hover {
  color: #ffab37;
}
.header.is-contrasted .header__contacts-title {
  color: #ffffff;
}
.header.is-contrasted .header__contacts-title::before {
  background: url("../img/icons/phone-yellow.svg") no-repeat center center;
}
.header.is-contrasted .header__contacts a {
  color: #ffffff;
}
.header.is-contrasted .header__nav-item_parent > a:before {
  border: 1.5px solid #ffffff;
  border-top: 0;
  border-left: 0;
}
.header.is-contrasted .header__burger span {
  background: #ffffff;
}
/* search */
.search {
  position: relative;
  border-bottom: 1px solid #000000;
  height: 38px;
}
.search__input {
  width: 100%;
  height: 100%;
  padding: 9px 0 9px 30px;
  background: transparent;
  border: none;
  outline: none;
  transition: 0.25s linear;
}
.search:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 9px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("../img/icons/search.svg");
  border: none;
  transition: 0.33s ease;
}
/* lang-switcher */
.lang-switcher {
  display: flex;
  align-items: center;
}
.lang-switcher__icon {
  display: flex;
  height: 15px;
}
.lang-switcher__icon svg {
  width: 15px;
  height: 15px;
}
.lang-switcher__langs {
  position: relative;
  margin-left: 8px;
  padding-right: 6px;
  cursor: pointer;
  transition: 0.25s linear;
}
.lang-switcher__current {
  transition: 0.25s linear;
}
.lang-switcher__list {
  display: flex;
}
.lang-switcher__list-item {
  position: relative;
  margin-right: 15px;
}
.lang-switcher__list-item span,
.lang-switcher__list-item a {
  transition: 0.25s linear;
}
.lang-switcher__list-item span {
  cursor: text;
}
.lang-switcher__list-item_current a,
.lang-switcher__list-item_current span {
  font-weight: 600;
}
.lang-switcher__list-item::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -7.5px;
  transform: translateY(-50%);
  display: block;
  height: 80%;
  width: 2px;
  background: #070606;
  transition: 0.25s linear;
}
.lang-switcher__list-item:last-child {
  margin-right: 0;
}
.lang-switcher__list-item:last-child::after {
  display: none;
}
/* custom list (yellow rect) */
.custom-list li {
  position: relative;
  padding-left: 35px;
}
.custom-list li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 20px;
  height: 3px;
  border-radius: 2px;
  background: #ffab37;
}
/* footer */
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5vh 0;
  text-align: center;
  background: #030B27;
}
@media screen and (max-width: 991px) {
  .footer {
    display: block;
  }
}
@media screen and (max-width: 767px) {
}
.footer__nav {
  position: relative;
  margin-right: 2vw;
}
.footer__nav:after {
  content: '';
  position: absolute;
  top: 0;
  right: -0.75vw;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #ffffff;
}
.footer__nav-list {
  display: flex;
}
@media screen and (max-width: 991px) {
  .footer__nav-list {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .footer__nav-list {
    display: block;
  }
}
.footer__nav-item {
  margin: 0 1vw;
}
@media screen and (max-width: 991px) {
  .footer__nav-item {
    width: 45%;
    margin: 5px 0;
  }
}
@media screen and (max-width: 767px) {
  .footer__nav-item {
    margin: 10px 0;
    width: 100%;
  }
}
.footer__nav-item a {
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #000000;
  font-size: 0.77em;
  line-height: 1;
  letter-spacing: 0;
  font-weight: normal;
  color: #ffffff;
  white-space: nowrap;
}
@media (min-aspect-ratio: 16/9) {
  .footer__nav-item a {
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 991px) {
  .footer__nav-item a {
    font-size: 14px;
  }
}
.footer__nav-item a:hover {
  color: #ffab37;
}
.footer__info,
.footer__info-dev {
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #000000;
  font-size: 0.77em;
  line-height: 1;
  letter-spacing: 0;
  font-weight: normal;
  color: #ffffff;
}
@media (min-aspect-ratio: 16/9) {
  .footer__info,
  .footer__info-dev {
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 991px) {
  .footer__info,
  .footer__info-dev {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .footer__info,
  .footer__info-dev {
    display: block;
    margin: 5px auto 0;
    font-size: 14px;
    line-height: 17px;
  }
}
.footer__info-dev {
  margin-left: 25px;
}
@media screen and (max-width: 575px) {
  .footer__info {
    position: relative;
    padding-top: 8px;
  }
  .footer__info::before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    display: block;
    height: 1px;
    background: #ffffff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 10%, #ffffff 20%, rgba(255, 255, 255, 0.95) 30%, #ffffff 50%, rgba(255, 255, 255, 0.95) 70%, rgba(255, 255, 255, 0.9) 80%, rgba(255, 255, 255, 0.8) 90%, rgba(255, 255, 255, 0) 100%);
  }
}
/* hero */
.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.hero__item {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 991px) {
  .hero__item {
    min-height: unset !important;
    height: 50vh;
  }
}
@media screen and (max-width: 767px) {
  .hero__item {
    justify-content: space-between;
  }
}
.hero__item-img {
  width: 100%;
  height: 78vh;
  object-fit: cover;
}
@media screen and (min-aspect-ratio: 16/9) {
  .hero__item-img {
    height: 75vh;
  }
}
@media screen and (max-width: 991px) {
  .hero__item-img {
    height: 70%;
  }
}
.hero__item-content {
  width: 100%;
  height: 23vh;
  padding: 9vh 8vw 6vh 25vw;
  background: #030B27;
}
@media screen and (min-aspect-ratio: 16/9) {
  .hero__item-content {
    height: 25vh;
    padding: 7vh 20vw 0 25vw;
  }
}
@media screen and (max-width: 1199px) {
  .hero__item-content {
    padding: 24px 20% 48px 20%;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .hero__item-content {
    height: 200px;
    padding: 24px 120px 30px;
  }
}
@media screen and (max-width: 767px) {
  .hero__item-content {
    padding: 12px 5% 30px;
    height: 200px;
  }
}
.hero__item-text {
  height: 8vh;
  max-height: min-content;
  margin: 0 0 0 auto;
  color: #ffffff;
}
@media screen and (max-width: 1199px) {
  .hero__item-text {
    margin: 0 auto;
  }
}
@media screen and (max-width: 575px) {
  .hero__item-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 991px) {
  .hero {
    min-height: unset !important;
    height: 50vh;
    justify-content: flex-start;
    margin-top: 56px;
  }
}
/*slider nav*/
/*
.slider-nav {
    position: absolute;
    top: 80vh;
    left: 25vw;
    z-index: 999;
    display: flex;
    align-items: center;

    @media screen and (min-aspect-ratio: ~'16/9') {
        top: 77vh;
    }

    @media screen and (max-width: 1199px) {
        left: 120px;
    }

    @media screen and (max-width: 767px) {
        left: 50%;
        top: unset;
        bottom: 160px;
        transform: translateX(-50%);

        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 5%;
    }

    &__button {
        width: 20px;
        height: 16px;

        cursor: pointer;

        svg {
            width: 100%;
        }
    }
}

.dots {

    &__list {
        display: flex;
        align-items: center;
        margin: 0 20px;

        @media screen and (max-width: 767px) {
            margin: 0 10px;
        }

        li {
            display: flex;
            align-items: center;
        }
    }

    &__item,
    button {
        position: relative;
        width: 60px;
        height: 2px;
        margin: 1px 10px 0;
        background: @grey-light;
        border: none;
        border-radius: 2px;

        overflow: hidden;
        font-size: 0;

        @media screen and (max-width: 767px) {
            width: 30px;
            margin: 0 5px;
        }

        &::before {
            content: '';

            position: absolute;
            top: -1px;
            left: 0px;
            width: 100%;
            height: 4px;
            background: @yellow;
            border-radius: 4px;

            transform: translateX(-100%);

        }



    }

    .slick-active {

        .dots__item,
        button {
            &::before {
                animation: timer 5s linear;
                animation-fill-mode: backwards;
            }
        }
    }
}
*/
.slider-nav {
  position: absolute;
  top: 80vh;
  left: 25vw;
  z-index: 999;
  display: flex;
  align-items: center;
}
@media screen and (min-aspect-ratio: 16/9) {
  .slider-nav {
    top: 77vh;
  }
}
@media screen and (max-width: 1199px) {
  .slider-nav {
    left: 50%;
    top: unset;
    bottom: 24px;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 991px) {
  .slider-nav {
    left: 50%;
    top: unset;
    bottom: 15px;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 767px) {
  .slider-nav {
    left: 50%;
    top: unset;
    bottom: 15px;
    transform: translateX(-50%);
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 5%;
  }
}
.slider-nav__button {
  width: 20px;
  height: 16px;
  cursor: pointer;
}
.slider-nav__button svg {
  width: 100%;
}
.dots__list {
  display: flex;
  align-items: center;
  margin: 0 20px;
}
@media screen and (max-width: 767px) {
  .dots__list {
    margin: 0 10px;
  }
}
.dots__list li {
  display: flex;
  align-items: center;
}
.dots__item,
.dots button {
  position: relative;
  width: 60px;
  height: 2px;
  margin: 1px 10px 0;
  background: #f3f3f3;
  border: none;
  border-radius: 2px;
  overflow: hidden;
  font-size: 0;
}
@media screen and (max-width: 767px) {
  .dots__item,
  .dots button {
    width: 30px;
    margin: 0 5px;
  }
}
.dots__item::before,
.dots button::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0px;
  width: 100%;
  height: 4px;
  background: #ffab37;
  border-radius: 4px;
  transform: translateX(-100%);
}
.dots .slick-active .dots__item::before,
.dots .slick-active button::before {
  animation: timer 5s linear;
  animation-fill-mode: backwards;
}
.callback {
  border-radius: 4px;
  padding: 40px 74px;
  background: #ffffff;
}
@media (min-aspect-ratio: 16/9) {
  .callback {
    margin-top: 2vh;
  }
}
@media only screen and (max-width: 767px) {
  .callback {
    padding: 35px 50px 25px;
  }
}
@media screen and (max-width: 575px) {
  .callback {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 15px 15px;
  }
}
.callback__title {
  font-family: 'Montserrat Alternates', serif;
}
@media screen and (max-width: 575px) {
  .callback__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #030B27;
  }
}
.callback__form {
  margin-top: 2.5vh;
}
@media (min-aspect-ratio: 16/9) {
  .callback__form {
    margin-top: 2vh;
  }
}
@media screen and (max-width: 575px) {
  .callback__form {
    width: 100%;
  }
  .callback__form .field {
    width: 100%;
  }
}
.form {
  background: #ffffff;
}
.field {
  width: 18vw;
  margin-bottom: 2vh;
  border-bottom: 1px solid #C6C8CE;
}
@media screen and (max-width: 991px) {
  .field {
    width: 220px;
  }
}
.field__label {
  display: block;
  font-weight: 400;
}
.field__input {
  width: 100%;
  border: none;
  background: transparent;
  font-weight: 400;
  padding: 7px 0 10px;
  outline: none;
}
.field__input::placeholder {
  color: #C6C8CE;
}
.field_select {
  border: 1px solid #C6C8CE;
}
.field_select::placeholder {
  color: #030B27;
}
.field_button {
  border-bottom: none;
  margin-top: 3vh;
}
.field_button input {
  margin: 0 auto;
}
.field.error {
  border-bottom-color: red !important;
}
.field.error .field__label {
  color: red !important;
}
.field.error input::placeholder {
  color: red !important;
}
.field-file {
  position: relative;
  text-align: center;
  overflow: hidden;
}
.field-file label {
  font-weight: 800;
  cursor: pointer;
}
.field-file input {
  position: absolute;
  top: 100%;
  left: 100%;
  opacity: 0;
  height: 1px;
  visibility: hidden;
}
.select {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(56, 55, 57, 0.8);
}
.select__checkbox {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 40px;
  opacity: 0;
}
.select__checkbox:checked ~ .select__list {
  max-height: 999px;
  transition: 1s ease;
}
.select__default {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 182px;
  height: 40px;
  padding: 6px 36px 7px 7px;
}
@media screen and (max-width: 767px) {
  .select__default {
    height: 36px;
  }
}
.select__value {
  display: inline-block;
  overflow: hidden;
}
.select__value span {
  font-family: 'Montserrat Alternates', serif;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #07174A;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .select__value span {
    font-size: 14px;
  }
}
.select__arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  display: block;
  width: 13px;
  height: 14px;
  background: url("../img/icons/arrow-down-form.svg") no-repeat center center;
  background-size: contain;
}
.select__list {
  position: relative;
  will-change: max-height;
  max-height: 0;
  overflow: hidden;
  width: 100%;
  transition: max-height 1s ease;
}
.select__list-item {
  transition: 0.25s ease;
}
.select__list-item input {
  position: absolute;
  top: -99px;
  left: -99px;
  opacity: 0;
  visibility: hidden;
}
.select__list-item input:checked ~ label {
  background: #ffab37;
  color: #ffffff;
}
.select__list-item label {
  display: block;
  width: 100%;
  padding: 10px 5px;
  font-family: 'Montserrat Alternates', serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #07174A;
}
@media screen and (max-width: 767px) {
  .select__list-item label {
    padding: 5px;
  }
}
.select__list-item:last-child {
  border-bottom: none;
}
.select__list-item:hover label {
  background: #f3f3f3;
  color: #030B27;
}
.legend__item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 22px;
  color: #030B27;
}
@media screen and (max-width: 575px) {
  .legend__item {
    font-size: 16px;
  }
}
.legend__item span {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #07174A;
  margin-right: 8px;
}
@media screen and (max-width: 575px) {
  .legend__item span {
    width: 20px;
    height: 20px;
  }
}
@keyframes timer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: -17vw;
  right: 0;
  bottom: 0;
  z-index: 99999;
  width: 100vw;
  display: none;
  background: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 1199px) {
  .overlay {
    position: fixed;
    left: 0;
    z-index: 9999;
  }
}
@media screen and (max-width: 767px) {
  .overlay {
    padding: 20px 30px;
  }
}
.overlay.is-active {
  display: block;
}
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999999;
  display: none;
  align-items: center;
  justify-content: center;
  width: 486px;
  max-width: 90%;
  height: 363px;
  padding: 30px 90px;
  border-radius: 4px;
  transform: translate(-15vw, -50%);
  background: url('../img/popup-bg-path.svg') no-repeat top right, #ffffff;
  background-size: contain;
}
.popup_cv {
  background: url('../img/popup-bg-cv.svg') no-repeat 12px top, #ffffff;
  background-size: contain;
}
@media screen and (max-width: 1199px) {
  .popup {
    position: fixed;
    z-index: 9999;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 767px) {
  .popup {
    padding: 20px 70px;
  }
}
@media screen and (max-width: 575px) {
  .popup {
    padding: 20px 30px;
  }
}
.popup.is-active {
  display: flex;
}
.popup__text {
  font-family: 'Montserrat Alternates', serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #070606;
}
.popup__text b {
  color: #ffffff;
  -webkit-text-stroke: 1px #030B27;
}
@media screen and (max-width: 767px) {
  .popup__text {
    font-size: 20px;
  }
}
.popup__close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.popup__close::before,
.popup__close::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  border-radius: 2px;
  background: #070606;
  transform: translateX(-50%) rotate(45deg);
}
.popup__close:after {
  transform: translateX(-50%) rotate(-45deg);
}
@media screen and (max-width: 575px) {
  .popup__close {
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
}
.popup-job {
  align-items: flex-start;
  padding: 20px 40px;
  width: 90%;
  left: 17vw;
  height: auto;
  max-height: 90%;
  overflow: hidden;
  padding: 30px 40px;
}
@media screen and (max-width: 1199px) {
  .popup-job {
    position: fixed;
    left: 50%;
    top: 100px;
    align-items: flex-start;
    transform: translate(-50%, 0);
    max-width: 90%;
    max-height: 80%;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 575px) {
  .popup-job {
    padding: 30px 20px 20px;
  }
}
.popup-job .popup__text {
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.15;
  text-align: left;
  height: 100%;
  transition: transform 0.33s ease-in-out;
}
.popup-job .popup__text li {
  font-family: 'Montserrat', serif;
  position: relative;
  padding-left: 10px;
}
.popup-job .popup__text li::before {
  content: '';
  position: absolute;
  top: 0.5em;
  left: 2px;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #000000;
}
.popup-job .popup__text b {
  font-family: 'Montserrat', serif;
  font-weight: 600;
  -webkit-text-stroke: unset;
  color: #030B27;
}
@media screen and (max-width: 1199px) {
  .popup-job .popup__text {
    font-size: 14px;
    overflow: hidden;
  }
}
.popup-job .popup__buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 30px;
  display: none;
}
.popup-job .popup__buttons.is-visible {
  display: block;
}
.popup-job .popup__buttons > div {
  width: 30px;
  height: 30px;
  margin: 10px 0;
  background: url("../img/icons/arrow-down-form.svg") no-repeat center center;
  background-size: 100%;
  cursor: pointer;
}
.popup-job .popup__buttons-top {
  display: none;
  transform: rotate(180deg);
}
.main-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;
  max-width: 100%;
  height: 100%;
}
#m-today-canvas {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  width: 100%;
}
.noscroll {
  overflow: hidden !important;
  height: 100vh !important;
}
.m-about {
  position: relative;
  padding-bottom: 17vh;
  height: 100vh;
}
.m-about__path {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 30%;
  max-width: 436px;
  max-height: 35vh;
  height: 239px;
}
.m-about__path svg,
.m-about__path object {
  height: auto;
}
@media screen and (max-width: 575px) {
  .m-about__path svg,
  .m-about__path object {
    width: 100%;
  }
}
@media (min-aspect-ratio: 16/9) {
  .m-about {
    padding-bottom: 10vh;
  }
}
@media screen and (max-width: 991px) {
  .m-about {
    height: auto;
    padding-bottom: 120px;
  }
}
@media screen and (max-width: 767px) {
  .m-about {
    padding: 60px 5% 84px;
    min-height: unset !important;
  }
}
@media screen and (max-width: 575px) {
  .m-about {
    padding: 40px 15px 136px;
    min-height: unset !important;
  }
}
.m-about__wrapper {
  position: relative;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10vh;
}
@media (min-aspect-ratio: 16/9) and (min-height: 850px) {
  .m-about__wrapper {
    margin-top: 12vh;
  }
}
@media (min-aspect-ratio: 16/9) and (max-height: 849px) {
  .m-about__wrapper {
    margin-top: 12vh;
  }
}
@media screen and (max-width: 991px) {
  .m-about__wrapper {
    margin-top: 72px;
  }
}
@media screen and (max-width: 767px) {
  .m-about__wrapper {
    margin-top: 36px;
  }
}
@media screen and (max-width: 575px) {
  .m-about__wrapper {
    margin-top: 0;
  }
}
.m-about__img {
  position: absolute;
  top: 0;
  left: 50px;
  z-index: 2;
  width: 36%;
  height: 50vh;
}
@media (min-aspect-ratio: 16/9) {
  .m-about__img {
    width: 30%;
    height: 64vh;
  }
}
@media screen and (max-width: 767px) {
  .m-about__img {
    height: 300px;
  }
}
@media screen and (max-width: 575px) {
  .m-about__img {
    position: relative;
    left: -15px;
    width: calc(100% + 15px);
    margin: 0;
  }
}
.m-about__img svg {
  filter: drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.25));
}
.m-about__img::before,
.m-about__img::after {
  content: '';
  position: absolute;
  top: -37px;
  left: -45px;
  z-index: -1;
  display: block;
  width: 100%;
  height: calc(100% + 19px);
  background-color: rgba(243, 243, 243, 0.5);
}
@media (min-aspect-ratio: 16/9) {
  .m-about__img::before,
  .m-about__img::after {
    top: -39px;
    left: -50px;
  }
}
@media screen and (max-width: 767px) {
  .m-about__img::before,
  .m-about__img::after {
    top: -20px;
    left: -25px;
  }
}
@media screen and (max-width: 575px) {
  .m-about__img::before,
  .m-about__img::after {
    top: 15px;
    left: -5px;
  }
}
.m-about__img::before {
  top: -19px;
  left: -22px;
  height: 100%;
  z-index: -2;
  background: rgba(198, 200, 206, 0.5);
}
@media (min-aspect-ratio: 16/9) {
  .m-about__img::before {
    top: -20px;
    left: -27px;
  }
}
@media screen and (max-width: 767px) {
  .m-about__img::before {
    top: -10px;
    left: -12px;
  }
}
@media screen and (max-width: 575px) {
  .m-about__img::before {
    top: 15px;
    left: -5px;
  }
}
.m-about__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m-about__text {
  width: 50%;
  margin-left: 41%;
  text-align: center;
  padding-top: 7vh;
}
@media (min-aspect-ratio: 16/9) {
  .m-about__text {
    width: 35vw;
    max-width: 600px;
    margin-left: 42%;
  }
}
@media (min-aspect-ratio: 16/9) and (min-height: 850px) {
  .m-about__text {
    padding-top: 8vh;
  }
}
@media screen and (max-width: 991px) {
  .m-about__text {
    margin-left: 49%;
    padding-top: 60px;
  }
}
@media screen and (max-width: 767px) {
  .m-about__text {
    padding-top: 36px;
  }
}
@media screen and (max-width: 575px) {
  .m-about__text {
    width: 100%;
    margin: 0 0 46px;
  }
}
.m-about__text .title-big {
  margin-bottom: 3vh;
}
@media (min-aspect-ratio: 16/9) and (max-height: 849px) {
  .m-about__text .title-big {
    margin-bottom: 2vh;
  }
}
@media screen and (max-width: 767px) {
  .m-about__text .title-big {
    margin-top: 18px;
  }
}
@media screen and (max-width: 575px) {
  .m-about__text .title-big {
    margin: 0 0 16px;
  }
}
.m-about__text * {
  color: #07174A;
}
.m-about__link {
  position: absolute;
  top: 100%;
  right: calc(9% + 60px);
  transform: translateY(22vh);
}
@media (min-aspect-ratio: 16/9) {
  .m-about__link {
    transform: translateY(16vh);
  }
}
@media screen and (max-width: 767px) {
  .m-about__link {
    right: calc(5%);
    transform: translateY(24px);
  }
}
@media screen and (max-width: 575px) {
  .m-about__link {
    right: 42px;
    transform: translateY(46px);
  }
}
.m-today {
  position: relative;
  background: #C6C8CE;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .m-today {
    padding-bottom: 0px;
    min-height: auto !important;
  }
}
.m-today__logo {
  position: absolute;
  top: 0;
  right: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11vw;
  height: 11vw;
  max-width: 220px;
  max-height: 220px;
}
.m-today__logo img {
  width: 75%;
  height: 75%;
  animation: rotate 10s linear infinite;
}
@media screen and (max-width: 991px) {
  .m-today__logo {
    width: 150px;
    height: 150px;
  }
}
.m-today__list {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 10%;
  margin-top: 9vh;
}
@media (min-aspect-ratio: 16/9) {
  .m-today__list {
    max-width: 1130px;
    margin-top: 7vh;
  }
}
@media screen and (max-width: 991px) {
  .m-today__list {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .m-today__list {
    margin-top: 36px;
  }
}
.m-today__item {
  border-top: 2px dashed #07174A;
  padding-bottom: 20px;
  opacity: 0;
  transform: translateY(50%);
}
@media (min-aspect-ratio: 16/9) {
  .m-today__item {
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
}
@media screen and (max-width: 575px) {
  .m-today__item {
    padding-top: 20px;
  }
  .m-today__item:first-child {
    border-top: none;
  }
}
.m-today__item-inner {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .m-today__item-inner {
    display: grid;
    grid-template-columns: 1fr 2fr 4fr;
  }
}
@media screen and (max-width: 575px) {
  .m-today__item-inner {
    display: flex;
    flex-wrap: wrap;
  }
}
.m-today__item .heading-alt {
  min-width: 133px;
  margin-right: 20px;
  color: white;
}
@media screen and (max-width: 991px) {
  .m-today__item .heading-alt {
    min-width: unset;
    width: auto;
  }
}
@media screen and (max-width: 575px) {
  .m-today__item .heading-alt {
    width: 30%;
    margin: 0;
  }
}
.m-today__item .text {
  max-width: 230px;
}
@media screen and (max-width: 991px) {
  .m-today__item .text {
    max-width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .m-today__item .text {
    width: 100%;
    margin-top: 20px;
  }
}
.m-today__item .text_white {
  width: 100px;
  margin-right: 26px;
  color: white;
}
@media screen and (max-width: 991px) {
  .m-today__item .text_white {
    width: auto;
  }
}
@media screen and (max-width: 575px) {
  .m-today__item .text_white {
    width: 30%;
    margin: 0;
  }
}
.m-today__next {
  position: absolute;
  bottom: 0;
  z-index: 9999;
  left: 45%;
  width: 55%;
  padding: 5vh 30px 4vh 60px;
  text-align: center;
}
@media (min-aspect-ratio: 16/9) {
  .m-today__next {
    width: 40%;
    left: 60%;
    padding: 4vh 1vw 3vh 2vw;
  }
}
@media screen and (max-width: 1366px) {
  .m-today__next {
    padding: 25px 50px 18px 70px;
    width: 45%;
    left: 55%;
  }
}
@media screen and (max-width: 991px) {
  .m-today__next {
    height: auto;
    padding: 30px 30px 30px 40px;
  }
}
@media screen and (max-width: 991px) {
  .m-today__next {
    position: relative;
    left: -30px;
    width: calc(100% + 60px);
    padding: 30px 45px 30px 30px;
    border-top: 2px dashed #FFAB37;
    border-top-left-radius: 60px;
    background: #ffffff;
  }
  .m-today__next img {
    opacity: 1 !important;
    visibility: visible !important;
    right: 0;
    top: 0;
    transform: translate(50%, -50%) rotate(180deg);
    animation: shipMoveHorizontaly 7s linear;
    animation-iteration-count: infinite;
  }
}
.m-today__next canvas {
  z-index: -1;
}
.m-today__next svg,
.m-today__next object {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: auto;
  min-height: 100%;
}
@media screen and (max-width: 1199px) {
  .m-today__next svg,
  .m-today__next object {
    width: auto;
    height: auto;
    min-height: 120%;
    min-width: 130%;
  }
}
@media screen and (max-width: 991px) {
  .m-today__next svg,
  .m-today__next object {
    left: -4vw;
  }
}
.m-today.is-visible .m-today__item {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.m-today.is-visible .m-today__item:nth-child(1),
.m-today.is-visible .m-today__item:nth-child(4) {
  transition: transform 0.75s ease-in-out 0.5s, opacity 0.75s ease-in-out 0.5s;
}
.m-today.is-visible .m-today__item:nth-child(2),
.m-today.is-visible .m-today__item:nth-child(5) {
  transition: transform 0.75s ease-in-out 1s, opacity 0.75s ease-in-out 1s;
}
.m-today.is-visible .m-today__item:nth-child(3),
.m-today.is-visible .m-today__item:nth-child(6) {
  transition: transform 0.75s ease-in-out 1.5s, opacity 0.75s ease-in-out 1.5s;
}
@media screen and (max-width: 768px) {
  .m-today.is-visible .m-today__item:nth-child {
    transition-delay: 0s !important;
  }
}
.m-today.is-visible .m-today__next {
  opacity: 1;
  transition: opacity 0.5s ease 2s;
}
@keyframes shipMoveHorizontaly {
  0% {
    right: 0;
    top: 0;
    transform: translate(50%, -50%) rotate(180deg);
  }
  80% {
    top: 0;
    right: calc(100% - 45px);
    transform: translate(50%, -50%) rotate(180deg);
  }
  100% {
    right: 100%;
    top: 40px;
    transform: translate(0%, -50%) rotate(90deg);
  }
}
.m-activity {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 4vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .m-activity {
    padding-bottom: 0;
  }
}
.m-activity__title {
  padding: 0 2vw;
}
@media screen and (max-width: 767px) {
  .m-activity__title {
    padding: 0 5%;
  }
}
.m-activity__list {
  position: relative;
  min-height: 57%;
  padding-bottom: 2vh;
  background: #F3F3F3;
  background-position-y: 200px;
}
@media (min-aspect-ratio: 16/9) {
}
@media screen and (max-width: 991px) {
  .m-activity__list {
    margin-top: 40px;
  }
}
.m-activity__item {
  width: 54%;
  margin: 0 0 0 auto;
  padding: 0 2vw;
}
@media (min-aspect-ratio: 16/9) {
  .m-activity__item {
    width: 40%;
  }
}
@media screen and (max-width: 1366px) {
  .m-activity__item {
    width: 44%;
    padding: 0 30px;
  }
}
@media screen and (max-width: 991px) {
  .m-activity__item {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .m-activity__item {
    padding: 0 15px;
  }
}
.m-activity__item-top {
  position: relative;
  padding: 2vh 0 2vh 22px;
  cursor: pointer;
}
@media screen and (max-width: 575px) {
  .m-activity__item-top .title-alt {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.m-activity__item-button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.m-activity__item-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  height: 10px;
  width: 10px;
  border: 3px solid #ffab37;
  border-left: none;
  border-top: none;
  border-radius: 3px;
  transform: translate(-50%, -65%) rotate(45deg);
  transition: 0.33s ease;
}
@media screen and (max-width: 575px) {
}
@media screen and (max-width: 575px) {
  .m-activity__item-button {
    height: 19px;
  }
}
.m-activity__item-img {
  position: absolute;
  left: 2.5vw;
  bottom: 2vh;
  width: 34vw;
  padding-top: 20vw;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease 0.5s;
}
@media screen and (max-width: 991px) {
  .m-activity__item-img {
    position: relative;
    left: 0;
    width: 100%;
    height: 170px;
    margin-top: 24px;
    padding-top: 50%;
    background: transparent;
  }
}
@media screen and (max-width: 575px) {
  .m-activity__item-img {
    height: auto;
    padding-top: 90%;
  }
}
.m-activity__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-3%, -4%);
}
@media screen and (max-width: 991px) {
  .m-activity__item-img img {
    transform: translate(0, 0);
  }
}
.m-activity__item-bottom {
  max-height: 0;
  padding-left: 40px;
  overflow: hidden;
  transition: 0.5s ease;
}
@media screen and (max-width: 991px) {
  .m-activity__item-bottom {
    padding-left: 0;
  }
}
@media screen and (max-width: 575px) {
  .m-activity__item-bottom {
    margin-left: -15px;
  }
  .m-activity__item-bottom .text {
    padding-left: 15px;
  }
}
.m-activity__item.is-active .m-activity__item-bottom {
  max-height: 100vh;
  transition: 1s ease 0.5s;
}
.m-activity__item.is-active .m-activity__item-img {
  opacity: 1;
  visibility: visible;
}
.m-activity__item.is-active .m-activity__item-button:after {
  transform: translate(-50%, -50%) rotate(225deg);
}
.m-activity__path {
  position: absolute;
  top: 0;
  left: 45%;
  bottom: 0;
  display: block;
  width: 55%;
  min-height: 100%;
  pointer-events: none;
}
.m-activity__path::before {
  content: '';
  position: absolute;
  top: 0;
  left: 9px;
  height: 100%;
  width: 2px;
  border-left: 2px dashed #FFAB37;
}
.m-activity__path img {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 2;
  transform: translate(-50%, -50%) rotate(90deg);
  animation: shipMove 15s linear ;
  animation-iteration-count: infinite;
}
.m-activity__path svg,
.m-activity__path object {
  height: 100%;
}
@media (min-aspect-ratio: 16/9) {
  .m-activity__path {
    left: 60%;
    width: 40%;
  }
}
@media only screen and (max-width: 1366px) {
  .m-activity__path {
    left: 55%;
    width: 45%;
  }
}
@media screen and (max-width: 991px) {
  .m-activity__path {
    display: none;
  }
}
@keyframes shipMove {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}
.m-cargo {
  position: relative;
  padding-right: 60%;
  overflow: hidden;
}
.m-cargo:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 35%;
  height: 50%;
  background: url("../img/m-cargo-bg.png") no-repeat left bottom;
  background-size: contain;
}
@media (min-aspect-ratio: 16/9) {
  .m-cargo:before {
    width: 25%;
  }
}
@media screen and (max-width: 767px) {
  .m-cargo:before {
    display: none;
  }
}
@media (min-aspect-ratio: 16/9) {
  .m-cargo {
    padding-right: 40%;
  }
}
@media screen and (max-width: 991px) {
  .m-cargo {
    padding-right: 5%;
    min-height: auto !important;
  }
}
@media screen and (max-width: 575px) {
  .m-cargo {
    padding-right: 15px;
  }
}
.m-cargo__title {
  margin-bottom: 13vh;
}
@media (min-aspect-ratio: 16/9) {
  .m-cargo__title {
    margin-bottom: 12vh;
  }
}
@media screen and (max-width: 991px) {
  .m-cargo__title {
    margin-bottom: 30px;
  }
}
.m-cargo__list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 45%;
  z-index: 9999;
  width: 55%;
  padding: 17vh 4% 7vh 4%;
}
@media (min-aspect-ratio: 16/9) {
  .m-cargo__list {
    left: 60%;
    width: 40%;
    padding: 15vh 2% 7vh 3%;
  }
}
@media screen and (max-width: 1366px) {
  .m-cargo__list {
    left: 55%;
    width: 45%;
  }
}
@media screen and (max-width: 991px) {
  .m-cargo__list {
    position: static;
    left: 0;
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
}
.m-cargo__path {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 45%;
  z-index: 1;
  width: 55%;
  min-height: 100vh;
}
.m-cargo__path img {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 2;
  transform: translate(-50%, -50%) rotate(90deg);
  animation: shipMove 15s linear ;
  animation-iteration-count: infinite;
}
.m-cargo__path::before {
  content: '';
  position: absolute;
  top: 0;
  left: 9px;
  height: 100%;
  width: 100%;
  border-left: 2px dashed #FFAB37;
  background: #ffffff;
}
@media (min-aspect-ratio: 16/9) {
  .m-cargo__path {
    left: 60%;
    width: 40%;
  }
}
@media screen and (max-width: 1366px) {
  .m-cargo__path {
    left: 55%;
    width: 45%;
  }
  .m-cargo__path:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 50%;
    background: #ffffff;
  }
}
@media screen and (max-width: 991px) {
  .m-cargo__path {
    display: none;
  }
}
.m-cargo__item {
  margin-bottom: 6vh;
  position: relative;
  opacity: 0;
  transform: translateY(50%);
}
.m-cargo__item-title {
  position: relative;
  margin-bottom: 1em;
  transition: 0.25s ease;
  padding-right: 56px;
}
.m-cargo__item-title span {
  display: inline-block;
  padding-right: 20px;
  position: relative;
  z-index: 1;
  background: #ffffff;
}
@media screen and (max-width: 991px) {
  .m-cargo__item-title span {
    background: #F3F3F3;
  }
}
@media screen and (max-width: 575px) {
  .m-cargo__item-title span {
    background: transparent;
    padding-bottom: 8px;
  }
}
.m-cargo__item-title::before,
.m-cargo__item-title::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 56px;
  display: block;
  transform: translateY(-50%);
}
.m-cargo__item-title::before {
  height: 2px;
  border-top: 2px dashed #C6C8CE;
}
@media screen and (max-width: 575px) {
  .m-cargo__item-title::before {
    top: 100%;
  }
}
.m-cargo__item-title::after {
  left: unset;
  right: 0;
  width: 41px;
  height: 16px;
  background: url('../img/yellow-arrow.svg') no-repeat top center;
  background-size: contain;
  transform: translateY(-60%);
}
@media screen and (max-width: 575px) {
  .m-cargo__item-title::after {
    width: 25px;
    height: 15px;
  }
}
.m-cargo__item:hover .m-cargo__item-title {
  color: #ffab37;
}
.m-cargo.is-visible .m-cargo__item {
  opacity: 1;
  transform: translateY(0%);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
}
.m-cargo.is-visible .m-cargo__item:nth-child(1) {
  transition: opacity 0.75s ease-in-out 0.5s, transform 0.75s ease-in-out 0.5s;
}
.m-cargo.is-visible .m-cargo__item:nth-child(2) {
  transition: opacity 0.75s ease-in-out 1s, transform 0.75s ease-in-out 1s;
}
.m-cargo.is-visible .m-cargo__item:nth-child(3) {
  transition: opacity 0.75s ease-in-out 1.5s, transform 0.75s ease-in-out 1.5s;
}
.m-cargo.is-visible .m-cargo__item:nth-child(4) {
  transition-delay: 2s;
  transition: opacity 0.75s ease-in-out 2s, transform 0.75s ease-in-out 2s;
}
@media screen and (max-width: 768px) {
  .m-cargo.is-visible .m-cargo__item:nth-child {
    transition-delay: 0s !important;
  }
}
.m-cargo.is-visible .m-today__next {
  opacity: 1;
  transition: opacity 0.5s ease 2s;
}
.m-geography {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
@media screen and (max-width: 1199px) {
  .m-geography {
    height: auto;
    min-height: 100vh;
  }
}
.m-geography__path {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  padding-top: 12%;
  width: 55%;
}
@media (min-aspect-ratio: 16/9) {
  .m-geography__path {
    width: 40%;
  }
}
@media screen and (max-width: 1366px) {
  .m-geography__path {
    width: 45%;
    left: 55%;
    padding-top: 0;
    min-height: 23%;
  }
}
@media screen and (max-width: 991px) {
  .m-geography__path {
    padding-top: 25%;
  }
}
@media screen and (max-width: 575px) {
  .m-geography__path {
    width: 100%;
    padding-top: 50%;
    min-height: unset;
    right: unset;
    left: 50%;
  }
}
.m-geography__path svg,
.m-geography__path object {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
@media screen and (max-width: 991px) {
  .m-geography__path svg,
  .m-geography__path object {
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .m-geography__path svg,
  .m-geography__path object {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
  }
}
.m-geography__top {
  background: #C6C8CE;
  height: 52vh;
}
@media (min-aspect-ratio: 16/9) {
  .m-geography__top {
    padding-top: 5vh;
  }
}
@media screen and (max-width: 1199px) {
  .m-geography__top {
    height: auto;
    min-height: 52vh !important;
    padding-bottom: 3vh;
    padding-top: 7vh;
  }
}
@media screen and (max-width: 767px) {
  .m-geography__top {
    padding: 30px 5% 90px;
  }
}
@media screen and (max-width: 575px) {
  .m-geography__top {
    position: relative;
  }
}
.m-geography__top .section-title {
  color: #ffffff;
}
.m-geography__top .section-title span {
  -webkit-text-stroke-color: #ffffff;
  color: #C6C8CE;
}
.m-geography__carousel {
  position: relative;
  margin-top: 60px;
}
@media screen and (max-width: 575px) {
  .m-geography__carousel {
    position: static;
  }
}
.m-geography__list {
  display: flex;
  overflow: hidden;
  margin-top: 6vh;
}
@media (min-aspect-ratio: 16/9) {
  .m-geography__list {
    margin-top: 4vh;
  }
}
.m-geography__item {
  width: 20%;
}
@media (min-aspect-ratio: 16/9) {
  .m-geography__item {
    width: 14%;
  }
}
.m-geography__item-title {
  position: relative;
  margin-bottom: 1.2vh;
  font-size: 1.2em;
}
@media (min-aspect-ratio: 16/9) {
  .m-geography__item-title {
    margin-bottom: 1.5vh;
  }
}
.m-geography__item-title:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  transform: translateY(1.5vh);
}
@media screen and (max-width: 575px) {
  .m-geography__item-list {
    padding-top: 20px;
    margin-right: 30px;
    border-top: 1px solid #ffffff;
  }
  .m-geography__item-list li {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 19px;
  }
}
.m-geography__nav {
  position: absolute;
  top: -4vh;
  right: 0;
  transform: translateY(-100%);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
}
.m-geography__nav-pages {
  display: flex;
  align-items: center;
  margin-right: 17px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #FFAB37;
}
.m-geography__nav-pages div {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #FFAB37;
}
.m-geography__nav-button {
  cursor: pointer;
}
@media screen and (max-width: 575px) {
  .m-geography__nav {
    top: unset;
    right: 15px;
    bottom: 40px;
    transform: none;
  }
}
.m-geography__bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 48vh;
  background: #f3f3f3;
}
@media screen and (max-width: 1199px) {
  .m-geography__bottom {
    position: relative;
    height: auto;
    min-height: 48vh;
  }
}
@media screen and (max-width: 767px) {
  .m-geography__bottom {
    min-height: unset;
  }
}
.m-geography__bottom svg {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  width: auto;
  height: 100%;
}
@media screen and (max-width: 1199px) {
  .m-geography__bottom svg {
    position: relative;
    transform: none;
  }
}
@media screen and (max-width: 767px) {
  .m-geography__bottom svg {
    max-width: 150%;
    height: auto;
  }
}
.m-geography__bottom-ukr {
  position: absolute;
  top: 0;
  right: 3vw;
  height: 39vh;
  width: 39vh;
  transform: translateY(-10%);
}
@media screen and (max-width: 991px) {
  .m-geography__bottom-ukr {
    right: unset;
    left: 30px;
  }
}
@media screen and (max-width: 991px) {
  .m-geography__bottom-ukr {
    right: unset;
    left: 30px;
    width: 180px;
    height: 180px;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 575px) {
  .m-geography__bottom-ukr {
    left: 0;
    width: 140px;
    height: 140px;
  }
}
.m-geography__bottom-ukr img {
  width: 100%;
  height: 100%;
}
.a-mission {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 3vh 0 0;
  background: url("../img/bg1.jpg") no-repeat bottom center;
  background-size: 100%;
}
@media screen and (max-width: 1199px) {
  .a-mission {
    height: auto !important;
    min-height: unset;
  }
}
@media screen and (max-width: 991px) {
  .a-mission {
    padding: 40px 0 0;
  }
}
@media screen and (max-width: 767px) {
  .a-mission {
    padding: 40px 0 0;
  }
}
.a-mission__title {
  padding-left: 2vw;
}
@media screen and (max-width: 991px) {
  .a-mission__title {
    padding-left: 30px;
  }
}
.a-mission__wrapper {
  position: relative;
  height: 100%;
}
.a-mission__description {
  position: absolute;
  top: 50%;
  left: 3vw;
  padding: 7.5vh 29% 4vh 30px;
  transform: translateY(-50%);
  background: linear-gradient(89.94deg, #07174A -1.97%, rgba(7, 23, 74, 0) 98.41%);
}
@media screen and (max-width: 1199px) {
  .a-mission__description {
    position: relative;
    margin: 120px 0 160px;
    transform: none;
  }
}
@media screen and (max-width: 767px) {
  .a-mission__description {
    left: 0;
    margin: 15px 0 200px 0px;
    padding: 35px 30px 25px 30px;
    background: linear-gradient(89.94deg, #07174A -1.97%, rgba(7, 23, 74, 0.5) 98.41%);
  }
}
.a-mission__description::before {
  content: '';
  position: absolute;
  top: 4.5vh;
  left: 30px;
  display: block;
  width: 164px;
  height: 3px;
  background: #ffffff;
}
@media screen and (max-width: 767px) {
  .a-mission__description::before {
    top: 25px;
    height: 1px;
  }
}
.a-mission__text {
  max-width: 588px;
  color: #ffffff;
}
.a-mission__text > * {
  margin-bottom: 0.5em;
}
@media screen and (max-width: 767px) {
  .a-mission__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .a-mission__text > * {
    margin-bottom: 0.2em;
  }
}
.canvas-main[resize] {
  width: 100%;
  height: 100%;
}
.contacts {
  position: relative;
  background: url("../img/contacts-bg.jpg") no-repeat center left;
  background-size: cover;
}
@media screen and (max-width: 1199px) {
  .contacts {
    min-height: unset !important;
    padding: 70px 30px 120px;
  }
}
@media screen and (max-width: 767px) {
  .contacts {
    padding: 40px 30px 230px;
  }
}
.contacts__wrapper {
  margin-top: 13vh;
}
@media (min-aspect-ratio: 16/9) {
  .contacts__wrapper {
    margin-top: 12vh;
  }
}
@media only screen and (max-width: 1199px) {
  .contacts__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding-bottom: 72px;
  }
}
@media screen and (max-width: 767px) {
  .contacts__wrapper {
    margin-top: 36px;
  }
}
@media screen and (max-width: 575px) {
  .contacts__wrapper {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .contacts__info {
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .contacts__info {
    width: 100%;
  }
}
.contacts__info-title {
  margin-bottom: 3vh;
}
@media screen and (max-width: 575px) {
  .contacts__info-title {
    margin-bottom: 20px;
  }
}
.contacts__info-item {
  position: relative;
  padding-left: 40px;
  margin-bottom: 1em;
}
@media screen and (max-width: 575px) {
  .contacts__info-item {
    margin-bottom: 20px;
  }
}
.contacts__info-item::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  background: url("../img/icons/phone-yellow.svg") no-repeat center center;
  transform: translateY(-50%);
}
.contacts__info-item a {
  color: #ffffff;
}
.contacts__info-item_mail:before {
  height: 16px;
  background-image: url("../img/icons/mail-yellow.svg");
}
.contacts__info-item_address:before {
  display: none;
}
.contacts__form {
  position: absolute;
  bottom: 14vh;
  right: 9vw;
}
@media only screen and (max-width: 1199px) {
  .contacts__form {
    position: static;
  }
}
.contacts-page .contacts .section-title > div span,
.contacts-page .contacts .section-title > div b,
.contacts-page .contacts .section-title > div div {
  transform: translateY(0px) skewY(0deg);
}
@media only screen and (min-height: 820px) {
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mouse {
  position: fixed;
  bottom: 30px;
  left: 56%;
  z-index: 9;
}
@media screen and (max-width: 1199px) {
  .mouse {
    display: none;
  }
}
.mouse.hidden {
  display: none;
}
.mouseScrollDown {
  background: #ffab37 -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), color-stop(50%, #ffffff), to(#ffffff));
  background: #ffab37 linear-gradient(transparent 0%, transparent 50%, #ffffff 50%, #ffffff 100%);
  position: relative;
  width: 26px;
  height: 39px;
  border-radius: 100px;
  background-size: 225%;
  cursor: pointer;
  opacity: 0.65;
  -webkit-animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
  animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}
.mouseScrollDown:before,
.mouseScrollDown:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.mouseScrollDown:before {
  width: 22px;
  height: 35px;
  background-color: #c2c1cb;
  border-radius: 100px;
}
.mouseScrollDown:after {
  background-color: #ffab37;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  -webkit-animation: trackBallSlide 5s linear infinite;
  animation: trackBallSlide 5s linear infinite;
}
@-webkit-keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }
  20% {
    background-position: 0% 0%;
  }
  21% {
    background-color: #ffab37;
  }
  29.99% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  30% {
    background-color: #ffab37;
    background-position: 0% 100%;
  }
  50% {
    background-position: 0% 0%;
  }
  51% {
    background-color: #ffab37;
  }
  59.99% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  60% {
    background-color: #ffab37;
    background-position: 0% 100%;
  }
  80% {
    background-position: 0% 0%;
  }
  81% {
    background-color: #ffab37;
  }
  89.99%,
  100% {
    background-color: #ffffff;
  }
}
@keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }
  20% {
    background-position: 0% 0%;
  }
  21% {
    background-color: #ffab37;
  }
  29.99% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  30% {
    background-color: #ffab37;
    background-position: 0% 100%;
  }
  50% {
    background-position: 0% 0%;
  }
  51% {
    background-color: #ffab37;
  }
  59.99% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  60% {
    background-color: #ffab37;
    background-position: 0% 100%;
  }
  80% {
    background-position: 0% 0%;
  }
  81% {
    background-color: #ffab37;
  }
  89.99%,
  100% {
    background-color: #ffffff;
  }
}
@-webkit-keyframes trackBallSlide {
  0% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  6% {
    opacity: 1;
    -webkit-transform: scale(0.9) translateY(2.5px);
    transform: scale(0.9) translateY(2.5px);
  }
  14% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(20px);
    transform: scale(0.4) translateY(20px);
  }
  15%,
  19% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(-10px);
    transform: scale(0.4) translateY(-10px);
  }
  28%,
  29.99% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  36% {
    opacity: 1;
    -webkit-transform: scale(0.9) translateY(2.5px);
    transform: scale(0.9) translateY(2.5px);
  }
  44% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(20px);
    transform: scale(0.4) translateY(20px);
  }
  45%,
  49% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(-10px);
    transform: scale(0.4) translateY(-10px);
  }
  58%,
  59.99% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  66% {
    opacity: 1;
    -webkit-transform: scale(0.9) translateY(2.5px);
    transform: scale(0.9) translateY(2.5px);
  }
  74% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(20px);
    transform: scale(0.4) translateY(20px);
  }
  75%,
  79% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(-10px);
    transform: scale(0.4) translateY(-10px);
  }
  88%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
}
@keyframes trackBallSlide {
  0% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  6% {
    opacity: 1;
    -webkit-transform: scale(0.9) translateY(2.5px);
    transform: scale(0.9) translateY(2.5px);
  }
  14% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(20px);
    transform: scale(0.4) translateY(20px);
  }
  15%,
  19% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(-10px);
    transform: scale(0.4) translateY(-10px);
  }
  28%,
  29.99% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  36% {
    opacity: 1;
    -webkit-transform: scale(0.9) translateY(2.5px);
    transform: scale(0.9) translateY(2.5px);
  }
  44% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(20px);
    transform: scale(0.4) translateY(20px);
  }
  45%,
  49% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(-10px);
    transform: scale(0.4) translateY(-10px);
  }
  58%,
  59.99% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
  66% {
    opacity: 1;
    -webkit-transform: scale(0.9) translateY(2.5px);
    transform: scale(0.9) translateY(2.5px);
  }
  74% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(20px);
    transform: scale(0.4) translateY(20px);
  }
  75%,
  79% {
    opacity: 0;
    -webkit-transform: scale(0.4) translateY(-10px);
    transform: scale(0.4) translateY(-10px);
  }
  88%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-10px);
    transform: scale(1) translateY(-10px);
  }
}
@-webkit-keyframes nudgeMouse {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  30% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  60% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  80% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes nudgeMouse {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  30% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  60% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  80% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
